import "./MapInput.scss";
import { ReactComponent as Custom } from "../../assets/icons/custom.svg";
import { ReactComponent as Chat } from "../../assets/icons/chat.svg";
import hide from "../../assets/icons/hide.svg";
import { ReactComponent as Refresh } from "../../assets/icons/refresh.svg";
import { useEffect, useState } from "react";
import useMindMap from "../../hooks/useMindMap";
import Question from "./Question/Question";
import { useNavigate } from "react-router-dom";
import IconComponent from "../IconComponent/IconComponent";

export default function MapInput() {
  const [questionType, setQuestionType] = useState<string>("preset");
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [isMapGenerated, setIsMapGenerated] = useState<boolean>(false);

  const { generateGraph, root, selectedNode } = useMindMap();

  const navigate = useNavigate();

  function handleQuestionType() {
    setQuestionType((prev) => {
      if (prev === "preset") {
        return "custom";
      }
      return "preset";
    });
  }

  useEffect(() => {
    if(root.id === selectedNode.id) {
      setIsHidden(false);
    }
    else {
      setIsHidden(true);
    }
    if (root.id !== "temp-node-1") {
      setIsMapGenerated(true);
    }
    else {
      setIsMapGenerated(false);
    }
  }, [root, selectedNode]);

  function handleHide() {
    setIsHidden((prev) => !prev);
  }

  function handleSubmit(prompt: string) {
    generateGraph(prompt).then((id) => {
      navigate(`/map/${id}`);
    });
  }

  return (
    <div className={`map_input${isHidden ? " hide" : ""}`}>
      <div className="map_input__hide">
        <img src={hide} alt="hide" onClick={handleHide}></img>
      </div>
      <div className="map_input__input">
        <Question
          type={questionType}
          handleSubmit={handleSubmit}
          isMapGenerated={isMapGenerated}
        ></Question>
        <div className="map_input__input__change_mode">
          {!isMapGenerated ? (
            <div
              className="map_input__input__change_mode__button"
              onClick={handleQuestionType}
            >
              <IconComponent
                Icon={questionType === "preset" ? Custom : Chat}
              ></IconComponent>
            </div>
          ) : (
            <div
              className="map_input__input__change_mode__button"
              onClick={() => handleSubmit(root.data.prompt)}
            >
              <IconComponent Icon={Refresh}></IconComponent>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
