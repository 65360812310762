import IconComponent from "../IconComponent/IconComponent";
import "./OptionsDropdown.scss";

interface OptionsDropdownProps {
  options: {
    src: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    text: string;
    alt: string;
  }[];
  className?: string;
  selectedOption: number;
  handleOptionClick: (index: number) => void;
}

export default function OptionsDropdown({
  options,
  className,
  handleOptionClick,
  selectedOption,
}: OptionsDropdownProps) {
  return (
    <div className={`options_dropdown ${className}`}>
      {options.map((option, index) => {
        return (
          <div
            className={`options_dropdown__item ${
              selectedOption === index ? "selected" : ""
            }`}
            key={index}
            onClick={() => {
              handleOptionClick!(index);
            }}
          >
            <div className="options_dropdown__item__icon">
              <IconComponent Icon={option.src}></IconComponent>
            </div>
            <div className="options_dropdown__item__text">{option.text}</div>
          </div>
        );
      })}
    </div>
  );
}
