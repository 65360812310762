import { Edge, Node } from "reactflow";

export function getEdgesForNodes(nodes: Node[], parentId: string): Edge[] {
  const edges: Edge[] = [];
  nodes.forEach((node) => {
    if (node.id === parentId) return;
    edges.push({
      id: `edge-${node.id}`,
      source: `${parentId}`,
      target: node.id,
      sourceHandle: `${parentId}d`,
      type: "smoothstep",
      style: { stroke: "#000" },
    });
  });
  return edges;
}
