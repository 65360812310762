import { useContext } from "react";
import MindMapContext from "../contexts/MindMapContext";

export default function useMindMap() {
  const context = useContext(MindMapContext);
  if (context === undefined) {
    throw new Error("useMindMap must be used within an MindMapContextProvider");
  }
  return context;
}
