import "./Sidepanel.scss";
import close from "../../assets/icons/close.svg";
import description from "../../assets/icons/description.svg";
import folder from "../../assets/icons/folder.svg";
import evaluation from "../../assets/icons/evaluation.svg";
import { ReactComponent as Open } from "../../assets/icons/open.svg";
import { ReactComponent as Refresh } from "../../assets/icons/refresh.svg";
import { ReactComponent as Prompt } from "../../assets/icons/prompt.svg";
import useMindMap from "../../hooks/useMindMap";
import { useState } from "react";
import SidepanelTheory from "./SidepanelTheory/SidepanelTheory";
import SidepanelEvaluation from "./SidepanelEvaluation/SidepanelEvaluation";
import IconComponent from "../IconComponent/IconComponent";
import SidepanelResources from "./SidepanelResources/SidepanelResources";
import send from "../../assets/icons/send.svg";
import Input from "../MapInput/Input/Input";
const navOption = [
  {
    icon: description,
    title: "Description",
  },
  {
    icon: folder,
    title: "Resources",
  },
  {
    icon: evaluation,
    title: "Evaluation",
  },
];

export default function Sidepanel() {
  const { selectedNode, onNodeClick, expandNode, isGenerating, askQuestion, redoContent } =
    useMindMap();

  const [showCustomQuestion, setShowCustomQuestion] = useState(false);

  const [question, setQuestion] = useState("");

  const options = [
    {
      src: Refresh,
      text: "Redo Content",
      onClick: () => {
        redoContent(selectedNode);
      },
    },
    {
      src: Prompt,
      text: "Custom Question",
      onClick: () => {
        setShowCustomQuestion(true);
      },
    },
    {
      src: Open,
      text: "Expand topic",
      onClick: () => {
        expandNode(selectedNode);
      },
    },
  ];

  const middle = [
    <SidepanelTheory
      theory={selectedNode.data.theory}
      questions={selectedNode.data.custom_questions}
    ></SidepanelTheory>,
    <SidepanelResources
      videos={selectedNode.data.videos || []}
    ></SidepanelResources>,
    <SidepanelEvaluation></SidepanelEvaluation>,
  ];

  const [selectedOption, setSelectedOption] = useState(0);

  if (selectedNode.id === "temp-node-1") {
    return <></>;
  }

  const title = selectedNode?.data.text || "Mind Map";

  function handleClose() {
    onNodeClick(false);
  }

  function handleAskQuestion() {
    askQuestion(selectedNode, question);
    setQuestion("");
    setShowCustomQuestion(false);
  }

  return (
    <div className="side_panel">
      <div className="side_panel__top">
        <div className="side_panel__top__header">
          <div className="side_panel__top__header__title">{title}</div>
          <div className="side_panel__top__header__close" onClick={handleClose}>
            <img src={close} alt="close" />
          </div>
        </div>
        <div className="side_panel__top__options">
          {navOption.map((option, index) => {
            return (
              <div
                className={`side_panel__top__options__option ${
                  selectedOption === index && "selected"
                }`}
                key={index}
                onClick={() => setSelectedOption(index)}
              >
                <div className="side_panel__top__options__option__img">
                  <img src={option.icon} alt={option.title} />
                </div>
                <div className="side_panel__top__options__option__title">
                  {option.title}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="side_panel__middle">{middle[selectedOption]}</div>
      <div className="side_panel__bottom">
        {options.map((option, index) => {
          return (
            <button
              className={`side_panel__bottom__button`}
              key={index}
              onClick={option.onClick}
              disabled={isGenerating}
            >
              <div className="side_panel__bottom__button__img">
                <IconComponent Icon={option.src} />
              </div>
              <div className="side_panel__bottom__button__text">
                {option.text}
              </div>
            </button>
          );
        })}
      </div>
      {showCustomQuestion && (
        <div className="side_panel__custom_question">
          <div className="side_panel__custom_question__header">
            Custom Question
            <div
              className="side_panel__top__header__close"
              onClick={() => setShowCustomQuestion(false)}
            >
              <img src={close} alt="close" />
            </div>
          </div>
          <div className="side_panel__custom_question__input">
            <Input
              onChange={(e) => setQuestion(e.target.value)}
              value={question}
              isDisabled={isGenerating}
              onEnter={handleAskQuestion}
            ></Input>
            <div className="side_panel__custom_question__input__send">
              <img alt="send" src={send} onClick={handleAskQuestion}></img>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
