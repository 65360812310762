import "./MainSection.scss";

interface MainSectionProps {
  text: string;
  title: string;
  img1: string;
  img2: string;
}

export default function MainSection({
  title,
  text,
  img1,
  img2,
}: MainSectionProps) {
  return (
    <div className="home_page__main_section">
      <img
        src={img1}
        alt={img1}
        className="home_page__main_section__plus"
      ></img>
      <img
        src={img2}
        alt={img2}
        className="home_page__main_section__star"
      ></img>
      <div className="home_page__main_section__text">{text}</div>
      <div className="home_page__main_section__title">{title}</div>
    </div>
  );
}
