import { interpolateString } from "../scripts/interpolateString";
import { loadPrompt } from "../scripts/loadPrompt";
import { theoryPrompt } from "../texts/prompts";
import { NodeData } from "../types/NodeData";
import getJson from "./getJson";
import { getErrorToast } from "../utils/toastUtils";

export async function getTheory(
  topic: string,
  theory: string,
  path: NodeData
): Promise<string> {
  try {
    const prompt = loadPrompt("theoryPrompt", theoryPrompt);
    const data = await getJson(
      message(prompt, topic, JSON.stringify(path), theory)
    );
    return data.theory;
  } catch (error) {
    console.error(error);
    getErrorToast("An unexpected error occurred, please try again!");
    return "";
  }
}

const message = (prompt: string, topic: string, path: string, theory: string) =>
  interpolateString(prompt, { topic, path, theory });
