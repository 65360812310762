import { Handle, Position } from "reactflow";
import "./ActionNode.scss";

interface Data {
  text: string;
  handleIds: string[];
}

interface ActionNodeProps {
  data: Data;
}

export default function ActionNode({ data }: ActionNodeProps) {
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id={data.handleIds[0]}
        isConnectable={false}
        className="handle_top"
      ></Handle>
      <Handle
        type="source"
        position={Position.Right}
        id={data.handleIds[1]}
        isConnectable={false}
      ></Handle>
      <div className="action_node">{data.text}</div>
    </>
  );
}
