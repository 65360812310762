import "./NavMapLeft.scss";
import { ReactComponent as Plus } from "../../../../assets/icons/black-plus.svg";
import { ReactComponent as Download } from "../../../../assets/icons/black-download.svg";
import { ReactComponent as Open } from "../../../../assets/icons/open-2.svg";
import { ReactComponent as Hide } from "../../../../assets/icons/hide.svg";
import Button from "../../../Button/Button";
import { Tooltip } from "react-tooltip";
import { useState, useEffect, useRef } from "react";
import OptionsDropdown from "../../../OptionsDropdown/OptionsDropdown";
import useMindMap from "../../../../hooks/useMindMap";
import { handleDownload } from "../../../../mapUtils/downloadMap";
import { useNavigate } from "react-router-dom";
import NavbarBrand from "../../NavbarBrand/NavbarBrand";

interface NavMapLeftProps {
  isSmall: boolean;
}

export default function NavMapLeft({ isSmall }: NavMapLeftProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState<number>(-1);
  const navigate = useNavigate();
  const hamRef = useRef<HTMLDivElement>(null);

  const { graph, root } = useMindMap();
  const path = document.location.pathname
  

  const options = [
    {
      src: Plus,
      alt: "plus",
      text: "New Map",
      onClick: () => {
        if(path === "/map/new") return;
        navigate("/map/new");
      },
    },
    {
      src: Open,
      alt: "open",
      text: "Open Map",
      onClick: () => {
        navigate("/home");
      },
    },
    {
      src: Download,
      alt: "download",
      text: "Download Map",
      onClick: () => {
        handleDownload(graph, root);
      },
    },
  ];

  function handleOptionClick(index: number) {
    handleExpand();
    setSelectedOption(index);
    options[index].onClick();
  }
  function handleExpand() {
    setIsExpanded((prev) => !prev);
  }

  useEffect(() => {
    if (!navRef.current) {
      return;
    }

    let resizeTimeout: NodeJS.Timeout;

    const handleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        const navBar = navRef.current;
        if (navBar) {
          if (navBar.offsetWidth < 450) {
            navBar.classList.add("--narrow");
          } else {
            navBar.classList.remove("--narrow");
          }
        }
      }, 100);
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(navRef.current);

    return () => {
      resizeObserver.disconnect();
      clearTimeout(resizeTimeout);
    };
  }, []);

  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.key === "Escape") {
        setIsExpanded(false);
      }
    }
    function handleClickOutside(event: MouseEvent) {
      if (hamRef.current && !hamRef.current.contains(event.target as Node)) {
        setIsExpanded(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside, {
      passive: true,
    });
    document.addEventListener("keydown", handleEscapeKey, { passive: true });

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  });

  return (
    <div className={`nav_map_left ${isSmall ? "small" : ""}`} ref={navRef}>
      <NavbarBrand
        className="nav_map_left__brand"
        isSmall={isSmall}
      ></NavbarBrand>
      <div className="nav_map_left__options">
        {options.map((element, index) => (
          <Button
            tooltipProps={{
              "data-tooltip-id": element.alt + index,
              "data-tooltip-place": "bottom",
              "data-tooltip-content": element.text,
            }}
            key={index}
            onClick={element.onClick}
            src={element.src}
          >
            <Tooltip id={element.alt + index} className="tooltip"></Tooltip>
          </Button>
        ))}
      </div>
      <div
        className={`nav_map_left__expanded ${isExpanded ? "flip" : ""}`}
        ref={hamRef}
      >
        <Button src={Hide} onClick={handleExpand}></Button>
        {isExpanded && (
          <OptionsDropdown
            options={options}
            handleOptionClick={handleOptionClick}
            selectedOption={selectedOption}
            className="options_dropdown_left"
          />
        )}
      </div>
    </div>
  );
}
