import { useState } from "react";
import "./PopUp.scss";
import ConfirmationPopup from "../../../../components/ConfirmationPopup/ConfirmationPopup";
import CancelButton from "../../../../components/CancelButton/CancelButton";
import gif from "../../../../assets/gifs/apiKey.gif";

interface PopUpProps {
  onClose: () => void;
}

export default function PopUp({ onClose }: PopUpProps) {
  const [isClosed, setIsClosed] = useState<boolean>(false);

  function handleClose() {
    onClose();
    setIsClosed(true);
  }

  const confirmationPopupProps = {
    title: `Help & Support`,
    buttons: [<CancelButton onClick={handleClose} text="Close"></CancelButton>],
    handleClose: handleClose,
    message: <PopUpMessage></PopUpMessage>,
  };

  if (isClosed) {
    return <></>;
  }
  return <ConfirmationPopup {...confirmationPopupProps}></ConfirmationPopup>;
}

function PopUpMessage() {
  return (
    <div className="auth_message">
      <div className="auth_message__text">
        Welcome to the beta version of <strong>IWTLNOW!</strong>. To get
        started, simply enter your ChatGPT key in the settings.
      </div>
      <div className="auth_message__image">
        <img src={gif} alt="apiKeyGif"></img>
      </div>
      <div className="auth_message__text">
        Please note that your key is stored only on your device. However, for
        added security, we recommend using incognito mode or a different browser
        profile. Enjoy exploring and have fun!
      </div>
    </div>
  );
}
