import Button from "../../Button/Button";
import "./Settings.scss";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import { ReactComponent as Lock } from "../../../assets/icons/lock.svg";
import { ReactComponent as Prompt } from "../../../assets/icons/prompt.svg";
import { ReactComponent as Themes } from "../../../assets/icons/theme.svg";
import { ReactComponent as Save } from "../../../assets/icons/save.svg";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { useEffect, useRef, useState } from "react";
import OptionsDropdown from "../../OptionsDropdown/OptionsDropdown";
import APIKeyPopUp from "../../APIKeyPopUp/APIKeyPopUp";
import ManageThemePopUp from "./ManageThemePopUp/ManageThemePopUp";
import { useNavigate } from "react-router-dom";

const options = [
  {
    src: Lock,
    text: "Auth Key",
    alt: "Auth Key",
  },
  {
    src: Prompt,
    text: "Prompt Settings",
    alt: "Prompt",
  },
  {
    src: Themes,
    text: "Themes",
    alt: "Themes",
  },
  {
    src: Save,
    text: "Auto Save",
    alt: "Auto Save",
  },
];

export default function Settings() {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const settingsRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState<number>(-1);
  const [popup, setPopup] = useState<JSX.Element>(<></>);
  const navigate = useNavigate()

  function handleClose() {
    setSelectedOption(-1);
    setPopup(<></>);
    setShowDropdown(false);
  }
  function handleOptionClick(index: number) {
    setSelectedOption(index);
    if (index === 0) {
      setPopup(<APIKeyPopUp closeDropdown={handleClose}></APIKeyPopUp>);
    } else if (index === 1) {
      navigate("/prompts")
      handleClose();
    } else if (index === 2) {
      setPopup(<ManageThemePopUp></ManageThemePopUp>);
    }
  }

  function handleDropdown() {
    setShowDropdown((prev) => !prev);
  }

  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.key === "Escape") {
        handleClose();
      }
    }
    function handleClickOutside(event: MouseEvent) {
      if (
        settingsRef.current &&
        !settingsRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside, {
      passive: true,
    });
    document.addEventListener("keydown", handleEscapeKey, { passive: true });

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  return (
    <div className="settings" ref={settingsRef}>
      {popup}
      <Button
        onClick={handleDropdown}
        src={showDropdown ? Close : SettingsIcon}
      ></Button>
      {showDropdown && (
        <OptionsDropdown
          options={options}
          className="settings__blue"
          handleOptionClick={handleOptionClick}
          selectedOption={selectedOption}
        ></OptionsDropdown>
      )}
    </div>
  );
}
