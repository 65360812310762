import "./Dropdown.scss";

interface DropdownProps {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;

}

export default function Dropdown({ onChange }: DropdownProps) {
  return (
    <select className="map_dropdown" onChange={onChange}>
      <option value="learn">learn</option>
      <option value="plan">plan</option>
      <option value="create">create</option>
    </select>
  );
}
