export const first_prompt = `We are working on a tool that helps users learn by iteratively building a mindmap based on the user's responses and feedback. Each node represents a topic or subtopic and contains explanatory text and multiple-choice questions to test understanding. The first node of the mindmap provides a brief overview of the entire subject, while the first level contains initial paths for the user to explore.

    Suppose a user wants to learn about \${topic}. Provide the content for the starting node. Also return upto 3 paths that are possible from the current level. 
    
    Requirements:
    1. Each path must be an independent entity, enabling the user to learn the topic thoroughly.
    2. Avoid overwhelming the user with excessive information at any level; break topics into subtopics if necessary.
    3. The content should be detailed and specific, akin to a documentation/tutorial, referencing expert sources and detailed articles.
    4. Ensure the prompts are self-sufficient as they will not have any context of this conversation.
    5. Don't make any assumptions about user's knowledge on the topic. Avoid using jargons wherever possible.
    
    Output Format:
    Return a JSON object of following form:
    
    {
       "nodeData":NodeData,
       topics: ["Topic 1", "Topic 2", ...]
    }
    
    NodeData = {
      text: 'Topic Text (summarized to few words)',
      theory: 'Initial content with examples and context',
      questions: Question[]
    }
    
    Question = {
    question: string, 
    options: string[], 
    correctOption:string
    }
    
    For content, you must write a string with following format:
      1) Things needed to be bold should be enclosed in <span class = "bold"></span> tags.
      2) For bullet points, enclose them in <ul class = "list"><li></li></ul> tags.
      3) For italics, enclose them in <span class = "italic"></span> tags.
      4) To make a line consume the whole width, enclose it in <div class = "line"></div> tags.
      5) You can use code blocks or other html syntaxes to make the content as readable as possible.
    
    For questions, return multiple questions.`;

export const second_prompt = `We are working on a tool that helps users learn by iteratively building a mindmap based on the user's responses and feedback. Each node represents a topic or subtopic, containing explanatory text and multiple-choice questions to test understanding.

    Suppose a user wants to learn about \${subtopic} of \${topic}. Provide the content for the node about \${subtopic}. Also return upto 3 topics that are possible for the paths possible from the next level. You must also include a summary of whatever has been taught so far.
    
    Requirements:
    1. Each path must be an independent entity, enabling the user to learn the topic thoroughly.
    2. Avoid overwhelming the user with excessive information at any level; break topics into subtopics if necessary.
    3. The content should be detailed and specific, akin to a documentation/tutorial, referencing expert sources and detailed articles.
    4. Ensure the prompts are self-sufficient as they will not have any context of this conversation.
    5. Don't make any assumptions about user's knowledge on the topic. Avoid using jargons wherever possible.
    
    Output Format:
    Return a JSON object of following form:
    
    {
       "nodeData":NodeData,
       summary:"Summary",
        topics: ["Topic 1", "Topic 2", ...]
    }
    
    NodeData = {
      text: 'Topic Text (summarized to few words)',
      theory: 'Initial content with examples and context',
      questions: Question[]
    }
    
    Question = {
    question: string, 
    options: string[], 
    correctOption:string
    }
    
    For content, you must write a string with following format:
      1) Things needed to be bold should be enclosed in <span class = "bold"></span> tags.
      2) For bullet points, enclose them in <ul class = "list"><li></li></ul> tags.
      3) For italics, enclose them in <span class = "italic"></span> tags.
      4) To make a line consume the whole width, enclose it in <div class = "line"></div> tags.
      5) You can use code blocks or other html syntaxes to make the content as readable as possible.
    
    For questions, return multiple questions.
    The summary must be plain text
    `;

export const third_prompt = `We are working on a tool that helps users learn by iteratively building a mindmap based on the user's responses and feedback. Each node represents a topic or subtopic, containing explanatory text and multiple-choice questions to test understanding.
 Suppose a user wants to learn about \${subtopic} of \${topic}. Provide the content for the node about \${subtopic}. Also return upto 3 topics that are possible for the paths possible from the next level. You must also include a summary of whatever has been taught so far.
    
The summary of the breadcrumb of user's current path is:

\${summary}


    Requirements:
    1. Each path must be an independent entity, enabling the user to learn the topic thoroughly.
    2. Avoid overwhelming the user with excessive information at any level; break topics into subtopics if necessary.
    3. The content should be detailed and specific, akin to a documentation/tutorial, referencing expert sources and detailed articles.
    4. Ensure the prompts are self-sufficient as they will not have any context of this conversation.
    5. Don't make any assumptions about user's knowledge on the topic. Avoid using jargons wherever possible.
    
    Output Format:
    Return a JSON object of following form:
    
    {
       "nodeData":NodeData,
       summary:"Summary",
        topics: ["Topic 1", "Topic 2", ...]
       
       ]
    }
    
    NodeData = {
      text: 'Topic Text (summarized to few words)',
      theory: 'Initial content with examples and context',
      questions: Question[]
    }
    
    Question = {
    question: string, 
    options: string[], 
    correctOption:string
    }
    
    For content, you must write a string with following format:
      1) Things needed to be bold should be enclosed in <span class = "bold"></span> tags.
      2) For bullet points, enclose them in <ul class = "list"><li></li></ul> tags.
      3) For italics, enclose them in <span class = "italic"></span> tags.
      4) To make a line consume the whole width, enclose it in <div class = "line"></div> tags.
      5) You can use code blocks or other html syntaxes to make the content as readable as possible.
    
    For questions, return multiple questions.
    The summary must be plain text`;

export const fourth_prompt = `We are working on a tool that helps users learn by iteratively building a mindmap based on the user's responses and feedback. Each node represents a topic or subtopic, containing explanatory text and multiple-choice questions to test understanding.
A user is currently learning about \${subtopic} within the broader context of \${topic}. The user has indicated that the content for \${subtopic} needs improvement. Given the breadcrumb trail of the user's learning path and the set of nodes that need to be replaced, regenerate the nodes for \${subtopic}. Additionally, suggest up to three new topics for potential paths at the next level. Include a summary of what has been covered so far in the learning path.

Nodes to be replaced:

\${nodes}

User's current breadcrumb path:

\${breadcrumb}


    Requirements:
    1. Each path must be an independent entity, enabling the user to learn the topic thoroughly.
    2. Avoid overwhelming the user with excessive information at any level; break topics into subtopics if necessary.
    3. The content should be detailed and specific, akin to a documentation/tutorial, referencing expert sources and detailed articles.
    4. Ensure the prompts are self-sufficient as they will not have any context of this conversation.
    5. Don't make any assumptions about user's knowledge on the topic. Avoid using jargons wherever possible.
    
    Output Format:
    Return a JSON object of following form:

    {
    children: Node[]
    }
    
    Node = {
       "nodeData":NodeData,
       summary:"Summary",
        topics: ["Topic 1", "Topic 2", ...]
       
       ]
    }
    
    NodeData = {
      text: 'Topic Text (summarized to few words)',
      theory: 'Initial content with examples and context',
      questions: Question[]
    }
    
    Question = {
    question: string, 
    options: string[], 
    correctOption:string
    }
    
    For content, you must write a string with following format:
      1) Things needed to be bold should be enclosed in <span class = "bold"></span> tags.
      2) For bullet points, enclose them in <ul class = "list"><li></li></ul> tags.
      3) For italics, enclose them in <span class = "italic"></span> tags.
      4) To make a line consume the whole width, enclose it in <div class = "line"></div> tags.
      5) You can use code blocks or other html syntaxes to make the content as readable as possible.
    
    For questions, return multiple questions.
    The summary must be plain text`;

export const custom_question_prompt = `We are working on a tool that helps users learn by iteratively building a mindmap based on the user's responses and feedback. Each node represents a topic or subtopic, containing explanatory text and multiple-choice questions to test understanding.
 A user was at \${subtopic} of \${topic} and asked the following question: \${question}
 The content of the node is: \${content}
 The summary of the breadcrumb of user's current path is: \${summary}


 Your task is to reply with the answer to the user's query

    Requirements:
    1. Avoid overwhelming the user with excessive information.
    2. The content should be detailed and specific, akin to a documentation/tutorial, referencing expert sources and detailed articles.
    3. Don't make any assumptions about user's knowledge on the topic. Avoid using jargons wherever possible.
    4. Don't use markdown syntax anywhere
    Return a JSON object of following form:
    

    {
      answer: '',
    }
    For answer, you must write a string with following format:
      1) Things needed to be bold should be enclosed in <span class = "bold"></span> tags.
      2) For bullet points, enclose them in <ul class = "list"><li></li></ul> tags.
      3) For italics, enclose them in <span class = "italic"></span> tags.
      4) To make a line consume the whole width, enclose it in <div class = "line"></div> tags.
      5) You can use code blocks or other html syntaxes to make the content as readable as possible.
    
    For questions, return multiple questions.
    The summary must be plain text`;

export const redo_content_prompt = `We are working on a tool that helps users learn by iteratively building a mindmap based on the user's responses and feedback. Each node represents a topic or subtopic, containing explanatory text and multiple-choice questions to test understanding.
 A user was at \${subtopic} of \${topic} and did not like the content we provided them. Your task is to return a better content for them.
 The previous content of the node is: \${content}
 The summary of the breadcrumb of user's current path is: \${summary}


  Requirements:
  1. Avoid overwhelming the user with excessive information.
  2. The content should be detailed and specific, akin to a documentation/tutorial, referencing expert sources and detailed articles.
  3. Don't make any assumptions about user's knowledge on the topic. Avoid using jargons wherever possible.
  4. Don't use markdown syntax anywhere
  Return a JSON object of following form:
    

    {
      content: '',
    }
    For answer, you must write a string with following format:
      1) Things needed to be bold should be enclosed in <span class = "bold"></span> tags.
      2) For bullet points, enclose them in <ul class = "list"><li></li></ul> tags.
      3) For italics, enclose them in <span class = "italic"></span> tags.
      4) To make a line consume the whole width, enclose it in <div class = "line"></div> tags.
      5) You can use code blocks or other html syntaxes to make the content as readable as possible.
    
    For questions, return multiple questions.
    The summary must be plain text`;
