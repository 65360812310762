/*
    When generating, first prompt will be called to get the initial topics
    Each time we generate a node, we will also generate summary of current level and topics for next level 
*/

import { Edge, Node } from "reactflow";
import { Dispatch, SetStateAction } from "react";
import { Graph } from "../../contexts/MindMapContext";
import { getLayoutElements } from "../../mapUtils/rearrangeLayout";
import { getEdgesForNodes } from "../../mapUtils/getEdgesForNodes";
import { NodeData as PathData } from "../../types/NodeData";
import { createNextNode, createNode } from "../../mapUtils/getNodeFromJson";
import { handleAPI, handleApiWithChildren } from "./handleApi";
import {
  getCustomQuestionPrompt,
  getPromptLevel1,
  getPromptLevel2,
  getPromptNode1,
  getPromptRegenerateContentWithoutFeedback,
  getPromptRegenerateLevelWithoutFeedback,
} from "./promptHandler";

function addNodesToGraph(
  node: Node[],
  edge: Edge[],
  setGraph: Dispatch<SetStateAction<Graph>>
) {
  setGraph((prev) => {
    const _graph = getLayoutElements(
      [...prev.nodes, ...node],
      [...prev.edges, ...edge],
      "TB"
    );
    return _graph;
  });
}

export async function getNode1(
  topic: string,
  setGraph: Dispatch<SetStateAction<Graph>>,
  isAdmin: boolean
) {
  const prompt = getPromptNode1(topic);
  const data: any = await handleAPI(prompt, isAdmin);
  const nodeData = data[0].nodeData;
  const topics = data[0].topics;

  const node = createNode(nodeData);
  node.data.topic = topic;
  node.data.topics = topics;
  setGraph(getLayoutElements([node], [], "TB"));

  return node;
}

export async function getLevel1(
  node: Node,
  setGraph: Dispatch<SetStateAction<Graph>>,
  isAdmin: boolean
) {
  const topics = node.data.topics;
  const topic = node.data.topic;

  const prompts = topics.map((subtopic: string) =>
    getPromptLevel1(topic, subtopic)
  );
  const nodes = [];
  const edges = [];

  for (let index = 0; index < prompts.length; index++) {
    const prompt = prompts[index];
    try {
      const data: any = await handleAPI(prompt, isAdmin);
      const nodeData = data[0].nodeData;
      const topics = data[0].topics;
      const summary = data[0].summary;

      const node = createNextNode(nodeData, "node-1", index);
      node.data.topics = topics;
      node.data.summary = summary;

      const edge = getEdgesForNodes([node], "node-1");

      nodes.push(node);
      edges.push(...edge);

      addNodesToGraph([node], edge, setGraph);
    } catch (error) {
      console.error("Error processing prompt:", error);
    }
  }
  const _graph = getLayoutElements([node, ...nodes], [...edges], "TB");
  return _graph;
}

export async function getLevel2(
  graph: Graph,
  topic: string,
  node: Node,
  setGraph: Dispatch<SetStateAction<Graph>>,
  isAdmin: boolean
) {
  const summary = node.data.summary;
  const generatedTopics = node.data.generatedTopics || [];
  const topics = (node.data.topics || []).filter(
    (topic: string) => !generatedTopics.includes(topic)
  )
  const id = node.id;

  const prompts = topics.map((subtopic: string) =>
    getPromptLevel2(topic, subtopic, summary)
  );
  const nodes = [];
  const edges = [];

  for (let index = 0; index < prompts.length; index++) {
    const prompt = prompts[index];
    try {
      const data: any = await handleAPI(prompt, isAdmin);
      const nodeData = data[0].nodeData;
      const topics = data[0].topics;

      const node = createNextNode(nodeData, id, index);
      node.data.topics = topics;
      node.data.summary = summary;

      const edge = getEdgesForNodes([node], id);

      nodes.push(node);
      edges.push(...edge);

      addNodesToGraph([node], edge, setGraph);
    } catch (error) {
      console.error("Error processing prompt:", error);
    }
  }

  const _graph = getLayoutElements(
    [...graph.nodes, ...nodes],
    [...graph.edges, ...edges],
    "TB"
  );
  return _graph;
}

export async function generateNextLevelWithSubtopic(
  graph: Graph,
  topic: string,
  subtopic: string,
  node: Node,
  setGraph: Dispatch<SetStateAction<Graph>>,
  isAdmin: boolean
) {
  const summary = node.data.summary;
  const id = node.id;

  const regex = new RegExp(`^${id}\\.\\d+$`);

  const idx = graph.nodes
    .filter((n) => regex.test(n.id))
    .map((n) => n.id).length;

  const prompt = getPromptLevel2(topic, subtopic, summary);
  const nodes = [];
  const edges = [];

  try {
    const data: any = await handleAPI(prompt, isAdmin);
    const nodeData = data[0].nodeData;
    const topics = data[0].topics;

    const node = createNextNode(nodeData, id, idx);
    node.data.topics = topics;
    node.data.summary = summary;

    const edge = getEdgesForNodes([node], id);

    nodes.push(node);
    edges.push(...edge);

    addNodesToGraph([node], edge, setGraph);
  } catch (error) {
    console.error("Error processing prompt:", error);
  }

  const _graph = getLayoutElements(
    [...graph.nodes, ...nodes],
    [...graph.edges, ...edges],
    "TB"
  );
  return _graph;
}

export async function regenerateLevelWithoutFeedback(
  graph: Graph,
  topic: string,
  id: string,
  path: PathData,
  children: PathData[],
  subtopic: string,
  isAdmin: boolean,
  setGraph: Dispatch<SetStateAction<Graph>>
) {
  const prompt = getPromptRegenerateLevelWithoutFeedback(
    JSON.stringify(children),
    JSON.stringify(path),
    topic,
    subtopic
  );

  const newChildren: any[] = await handleApiWithChildren(prompt, isAdmin);

  const nodes = newChildren.map((child: any, index) => {
    const node = createNextNode(child.nodeData, id, index);
    node.data.topics = child.topics;
    node.data.summary = child.summary;
    return node;
  });

  const edges = getEdgesForNodes(nodes, id);

  addNodesToGraph(nodes, edges, setGraph);

  return getLayoutElements(
    [...graph.nodes, ...nodes],
    [...graph.edges, ...edges],
    "TB"
  );
}

export async function askQuestionFromNode(
  node: Node,
  topic: string,
  question: string,
  isAdmin: boolean
) {
  const subtopic = node.data.text;
  const content = node.data.theory;
  const summary = node.data.summary;

  const prompt = getCustomQuestionPrompt(
    topic,
    subtopic,
    question,
    content,
    summary
  );

  const response = ((await handleAPI(prompt, isAdmin)) as any)[0];

  return response.answer;
}

export async function regenerateContentWithoutFeedback(
  node: Node,
  topic: string,
  isAdmin: boolean
) {
  const subtopic = node.data.text;
  const content = node.data.theory;
  const summary = node.data.summary;

  const prompt = getPromptRegenerateContentWithoutFeedback(
    topic,
    subtopic,
    content,
    summary
  );

  const response = ((await handleAPI(prompt, isAdmin)) as any)[0];

  return response.content;
}
