import { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PromptMap from "../../components/PromptMap/PromptMap";
import PromptSidePanel from "../../components/PromptSidePanel/PromptSidePanel";
import "./Prompt.scss";
import { Node } from "reactflow";
import 'react-toastify/dist/ReactToastify.min.css';

export default function Prompt() {
  const [selectedNode, setSelectedNode] = useState<Node | undefined>();
  function setNode(node : Node | undefined) {
    setSelectedNode(node);
  }
  return (
    <div className="prompt_manager">
      <Navbar></Navbar>
      <div className="prompt_manager__left">
        <PromptMap setSelectedNode={setNode}></PromptMap>
      </div>
      <div className="prompt_manager__right">
        <PromptSidePanel selectedNode = {selectedNode} setSelectedNode={setNode}></PromptSidePanel>
      </div>
    </div>
  );
}
