import "./NavMap.scss";
import share from "../../../assets/icons/share.svg";
// import Searchbar from "../NavHome/Searchbar/Searchbar";
import Settings from "../Settings/Settings";
import useMindMap from "../../../hooks/useMindMap";
import NavMapLeft from "./NavMapLeft/NavMapLeft";
import { useEffect, useRef, useState } from "react";

export default function NavMap() {
  const { root, renameMap } = useMindMap();

  const navRef = useRef<HTMLDivElement>(null);
  const [isSmall, setIsSmall] = useState(false);
  // const [isSearchboxOpen, setIsSearchboxOpen] = useState<boolean>(false);
  const isSearchboxOpen = false;

  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!navRef.current) {
      return;
    }

    let resizeTimeout: NodeJS.Timeout;

    const handleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        const navBar = navRef.current;
        if (navBar) {
          if (navBar.offsetWidth < 1024) {
            setIsSmall(true);
          } else {
            setIsSmall(false);
          }
        }
      }, 100);
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(navRef.current);

    return () => {
      resizeObserver.disconnect();
      clearTimeout(resizeTimeout);
    };
  }, []);

  const handleTitleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (root.id !== "node-1") {
      return;
    }
    renameMap(inputRef.current?.value || root.data.text);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };

  return (
    <div className="nav_map" ref={navRef}>
      <div className="nav_map__left">
        <NavMapLeft isSmall={isSmall}></NavMapLeft>
      </div>
      <div
        className={`nav_map__middle ${isSearchboxOpen ? "hide" : ""}`}
        onClick={handleTitleClick}
      >
        {isEditing ? (
          <input
            type="text"
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            ref={inputRef}
            defaultValue={root.data.text}
            autoFocus
          />
        ) : (
          <>{root.data.text}</>
        )}
      </div>
      <div
        className={`nav_map__right  ${isSmall ? "small" : ""}  ${
          isSearchboxOpen ? "stretch" : ""
        }`}
      >
        {/* <Searchbar setIsSearchboxOpen={setIsSearchboxOpen} isSmall={isSmall}></Searchbar> */}
        {isSmall ? (
          <Settings></Settings>
        ) : (
          <>
            <Settings></Settings>
            <div className="nav_map__right__blue">
              <img src={share} alt="share"></img>
              Share
            </div>
          </>
        )}
      </div>
    </div>
  );
}
