import "./MapCard.scss";
import options from "../../assets/icons/options.svg";
import { MouseEvent } from "react";
import { useNavigate } from "react-router-dom";

export interface MapCardProps {
  title: string;
  time: string;
  id: string;
}

export default function MapCard({ time, title, id }: MapCardProps) {
  function handleMapOptions(e: MouseEvent<HTMLImageElement>) {
    e.stopPropagation();
  }
  const navigate = useNavigate();
  const path = window.location.pathname;
  function handleNavigate() {
    if (path === "/home") {
      handleHome();
    } else {
      handleRefresher();
    }
  }

  function handleHome() {
    navigate(`/map/${id}`);
  }

  function handleRefresher() {
    navigate(`/refresher/${id}`);
  }

  function handleDelete(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    const oldGraphs = JSON.parse(localStorage.getItem("graphs") || "[]");
    localStorage.setItem(
      "graphs",
      JSON.stringify([...oldGraphs.filter((graph: any) => graph.id !== id)])
    );
    window.dispatchEvent(new Event("updateGraphs"));
  }

  const masked_title = title.length > 30 ? title.slice(0, 30) + "..." : title;

  return (
    <div className="map_card" onClick={handleNavigate}>
      <div className="map_card__image"></div>
      <div className="map_card__content">
        <div className="map_card__content__title">
          {masked_title}
          <div className="map_card__content__title__button">
            <img src={options} alt="options" onClick={handleMapOptions}></img>
            <div className="map_card__content__title__button__options">
              <div
                className="map_card__content__title__button__options__item"
                onClick={handleHome}
              >
                Open Map
              </div>
              <div
                className="map_card__content__title__button__options__item"
                onClick={handleRefresher}
              >
                Open Refresher
              </div>
              <div className="map_card__content__title__button__options__item">
                Rename Map
              </div>
              <div
                className="map_card__content__title__button__options__item delete"
                onClick={handleDelete}
              >
                Delete Map
              </div>
            </div>
          </div>
        </div>
        <div className="map_card__content__time">{time}</div>
      </div>
    </div>
  );
}
