import "./Searchbar.scss";
import { ReactComponent as Search } from "../../../../assets/icons/search.svg";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Button from "../../../Button/Button";

function Input({
  isSmall,
  onSearch,
}: {
  isSmall: boolean;
  onSearch: (search: string) => void;
}) {
  function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      handleSearch();
    }
  }

  const inputRef = useRef<HTMLInputElement>(null);

  function handleSearch() {
    onSearch(inputRef.current?.value || "");
  }

  return (
    <div className={`search_bar_container ${isSmall ? "small" : ""}`}>
      <input
        type="text"
        placeholder="Search anything"
        className="search_bar_container__input"
        onKeyDown={handleKeyPress}
        ref={inputRef}
      ></input>
      <Search onClick={handleSearch}></Search>
    </div>
  );
}

interface SearchBarProps {
  setIsSearchboxOpen: Dispatch<SetStateAction<boolean>>;
  isSmall: boolean;
  onSearch: (search: string) => void;
}

export default function Searchbar({
  setIsSearchboxOpen,
  isSmall,
  onSearch,
}: SearchBarProps) {
  const [showInput, setShowInput] = useState<boolean>(false);
  const searchRef = useRef<HTMLDivElement>(null);

  function handleInput() {
    setIsSearchboxOpen((prev) => !prev);
    setShowInput((prev) => !prev);
  }

  useEffect(() => {
    function handleClose() {
      setIsSearchboxOpen(false);
      setShowInput(false);
    }
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.key === "Escape") {
        handleClose();
      }
    }
    function handleClickOutside(event: MouseEvent) {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside, {
      passive: true,
    });
    document.addEventListener("keydown", handleEscapeKey, { passive: true });

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [setIsSearchboxOpen]);

  return (
    <>
      <div className="nav_search_bar" ref={searchRef}>
        {showInput ? (
          <Input isSmall={isSmall} onSearch={onSearch}></Input>
        ) : (
          <Button onClick={handleInput} src={Search}></Button>
        )}
      </div>
    </>
  );
}
