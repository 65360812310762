import { Node } from "reactflow";
import { Graph } from "../contexts/MindMapContext";
import { NodeData } from "../types/NodeData";

export function getPath(
  graph: Graph,
  rootNodeId: string,
  targetNodeId: string
): NodeData | null {
  const visited: { [nodeId: string]: boolean } = {};
  const path: Node[] = [];

  function dfs(nodeId: string): boolean {
    visited[nodeId] = true;
    const node = graph.nodes.find((node) => node.id === nodeId);
    if (!node) return false;

    path.push(node);

    if (node.id === targetNodeId) return true;

    for (const edge of graph.edges) {
      if (edge.source === nodeId && !visited[edge.target]) {
        if (dfs(edge.target)) return true;
      }
    }

    path.pop();
    return false;
  }

  if (!dfs(rootNodeId)) return null;

  const user_data: NodeData = {
    text: "",
    children: [],
  };

  for (const node of path) {
    const { id, data } = node;
    const text = data.text as string;
    const theory = data.theory as string;
    const question = data.question as string;
    const options = data.options as string[];
    const selectedOption = data.selectedOption as string;
    if (id === rootNodeId) {
      user_data.text = text;
      continue;
    }
    user_data.children!.push({
      theory,
      question,
      selectedOption,
      options,
    });
  }

  return user_data;
}

export function getChildren(graph: Graph, parentNodeId: string): NodeData[] {
  const children: NodeData[] = [];

  function dfs(nodeId: string): void {
    const parentNode = graph.nodes.find((node) => node.id === nodeId);
    if (!parentNode) return;

    for (const edge of graph.edges) {
      if (edge.source === nodeId) {
        const childNode = graph.nodes.find((node) => node.id === edge.target);
        if (childNode) {
          const { id, data } = childNode;
          const theory = data.theory as string;
          const question = data.question as string;
          const options = data.options as string[];
          const selectedOption = data.selectedOption as string;
          const text = data.text as string;

          children.push({
            text,
            id,
            theory,
            question,
            selectedOption,
            options,
          });

          dfs(id);
        }
      }
    }
  }

  dfs(parentNodeId);
  return children;
}
