import OpenAI from "openai";
import { ChatCompletionMessage } from "openai/resources";
import { getErrorToast } from "../utils/toastUtils";

export default async function getJson(prompt: string): Promise<any> {
  const apiKey = localStorage.getItem("api_key");
  if (!apiKey) {
    getErrorToast("Please enter an API key");
    return;
  }
  try {
    const openai = new OpenAI({
      apiKey: apiKey,
      dangerouslyAllowBrowser: true,
    });

    const res = await openai.chat.completions.create({
      messages: [
        {
          role: "user",
          content: prompt,
        },
      ],
      response_format: { type: "json_object" },
      model: "gpt-3.5-turbo-0125",
    });
    return JSON.parse(res.choices[0].message.content!);
  } catch (error) {
    console.error(error);
    getErrorToast("An unexpected error occurred, please try again!");
    return {};
  }
}

export async function getJson2(prompts: string[]): Promise<any> {
  const apiKey = localStorage.getItem("api_key");
  if (!apiKey) {
    getErrorToast("Please enter an API key");

    return [];
  }
  try {
    const openai = new OpenAI({
      apiKey: apiKey,
      dangerouslyAllowBrowser: true,
    });
    let prevContext = "";
    let response = [];
    for (let prompt of prompts) {
      const messages = [
        {
          role: "system",
          content: prevContext,
        },
        {
          role: "user",
          content: prompt,
        },
      ].filter((message) => message.content !== "");
      const res = await openai.chat.completions.create({
        messages: messages as ChatCompletionMessage[],
        response_format: { type: "json_object" },
        model: "gpt-3.5-turbo-0125",
      });
      prevContext = res.choices[0].message.content!;
      response.push(JSON.parse(prevContext));
    }

    return response;
  } catch (error) {
    console.error(error);
    getErrorToast("An unexpected error occurred, please try again!");
    return [];
  }
}
