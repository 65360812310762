import { createContext, useCallback, useState } from "react";
import { Edge, Node, ReactFlowProvider } from "reactflow";
import { getCentralNode } from "../API_Calls/getCentralNode";
import { getEdgesForNodes } from "../mapUtils/getEdgesForNodes";
import { getLayoutElements } from "../mapUtils/rearrangeLayout";
import { getNextNodes } from "../API_Calls/getNextNodes";
import { getChildren, getPath } from "../mapUtils/getPath";
import { regenerateNodesWithoutFeedback } from "../API_Calls/regenerateNodes";
import { getTheory } from "../API_Calls/getTheory";
import { useAuth } from "../hooks/useAuth";
import {
  askQuestionFromNode,
  generateNextLevelWithSubtopic,
  getLevel1,
  getLevel2,
  getNode1,
  regenerateContentWithoutFeedback,
  regenerateLevelWithoutFeedback,
} from "../API_Calls/promptFlows/recursivePromptFlow";

const temp = {
  id: "temp-node-1",
  data: { text: "Title of the Mindmap" },
  position: { x: 0, y: 0 },
};

export interface Graph {
  nodes: Node[];
  edges: Edge[];
}

export interface GraphList {
  id: string;
  graph: Graph;
}

export interface MindMapContextType {
  root: Node;
  graph: Graph;
  setGraph: React.Dispatch<React.SetStateAction<Graph>>;
  graphID: string;
  selectedNode: Node;
  trayNode: Node | null;
  isGenerating: boolean;
  generateGraph: (prompt: string) => Promise<String>;
  expandNode: (node: Node, topic?: string) => Promise<void>;
  regenerateChildren: (node: Node) => Promise<void>;
  deleteNode: (node: Node) => void;
  onNodeClick: (node: Node | boolean) => void;
  addMoreTheory: (node: Node) => Promise<void>;
  onHandleClick: (node: Node | null) => void;
  loadGraph: (id: string) => boolean;
  answerQuestion: (option: string, index: number) => void;
  renameMap: (name: string) => void;
  askQuestion: (node: Node, question: string) => Promise<void>;
  redoContent: (node: Node) => Promise<void>;
}

const MindMapContext = createContext<MindMapContextType | undefined>(undefined);

export function MindMapContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { role } = useAuth();

  const isAdmin = role === "admin";

  const [graph, setGraph] = useState<Graph>({ nodes: [], edges: [] });

  const root = graph.nodes[0] || temp;
  const [selectedNode, setSelectedNode] = useState<Node>(root);
  const [trayNode, setTrayNode] = useState<Node | null>(null);
  const [graphID, setGraphID] = useState<string>("");
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  /*
  This function generates a graph based on the given prompt.
  */
  async function generateGraph(prompt: string): Promise<string> {
    setIsGenerating(true);
    if ([].length === 0) {
      const node: Node | undefined = await getNode1(prompt, setGraph, isAdmin);
      if (!node) {
        setIsGenerating(false);
        return "new";
      }
      node.data.isExpanded = true;
      const _graph = await getLevel1(node, setGraph, isAdmin);
      return saveGraph(_graph);
    }
    const node: Node | undefined = await getCentralNode(prompt, isAdmin);
    if (!node) {
      setIsGenerating(false);
      return "new";
    }
    node.data.prompt = prompt;
    const topic: string = node!.data.text;
    const id: string = node.id;
    const path = getPath(graph, node.id, id);
    const nodes: Node[] = await getNextNodes(topic, id, path!, isAdmin);
    const edges = getEdgesForNodes(nodes, id);
    node.data.isExpanded = true;
    setSelectedNode(node);
    const _graph = getLayoutElements([node, ...nodes], [...edges], "TB");
    setGraph(_graph);
    return saveGraph(_graph);
  }

  /*

  This function expands the given node by fetching the next nodes from the API and adding them to the graph.
  */
  async function expandNode(node: Node, subtopic?: string) {
    if (node.data.isExpanded) {
      return;
    }
    setIsGenerating(true);
    if (subtopic) {
      const _graph = await generateNextLevelWithSubtopic(
        graph,
        root!.data.topic,
        subtopic,
        node,
        setGraph,
        isAdmin
      );
      const generatedTopics = node.data.generatedTopics || [];
      generatedTopics.push(subtopic);
      node.data.generatedTopics = generatedTopics;
      saveGraph(_graph);
      return;
    }
    const _graph = await getLevel2(
      graph,
      root!.data.topic,
      node,
      setGraph,
      isAdmin
    );
    const generatedTopics = node.data.generatedTopics || [];
    const oldSubtopics = node.data.topics || [];
    generatedTopics.push(...oldSubtopics);
    node.data.generatedTopics = generatedTopics;
    saveGraph(_graph);
    return;
  }

  /*
  This function removes the nodes and edges with the given IDs from the graph and returns the updated graph.
  */
  function removeNodesAndEdges(nodeIDs: string[]): Graph {
    const filteredNodes = graph.nodes.filter(
      (node) => !nodeIDs.includes(node.id)
    );
    const filteredEdges = graph.edges.filter(
      (edge) => !nodeIDs.includes(edge.source) && !nodeIDs.includes(edge.target)
    );

    return { nodes: filteredNodes, edges: filteredEdges };
  }

  function deleteNode(node: Node) {
    const children = getChildren(graph, node.id);
    children.push(node);
    const newGraph = removeNodesAndEdges(children.map((x) => x.id!));
    const _graph = getLayoutElements(newGraph.nodes, newGraph.edges, "TB");
    setGraph(_graph);
    saveGraph(_graph);
  }

  /*
  This function regenerates the children of the given node by removing the children,
  fetching the new children from the API, and adding them to the graph.
  */
  async function regenerateChildren(node: Node) {
    setIsGenerating(true);

    if ([].length === 0) {
      const children = getChildren(graph, node.id).filter(
        (x) => x.id !== node.id
      );
      const newGraph = removeNodesAndEdges(children.map((x) => x.id!));
      setGraph(newGraph);
      const topic: string = root!.data.topic;
      const id: string = node.id;
      const path = getPath(graph, root.id, id);
      const subtopic = node.data.text;
      const _graph = await regenerateLevelWithoutFeedback(
        newGraph,
        topic,
        id,
        path!,
        children,
        subtopic,
        isAdmin,
        setGraph
      );
      saveGraph(_graph);
      return;
    }

    const children = getChildren(graph, node.id).filter(
      (x) => x.id !== node.id
    );
    const newGraph = removeNodesAndEdges(children.map((x) => x.id!));
    const topic: string = root!.data.topic;
    const id: string = node.id;
    const path = getPath(graph, root.id, id);
    const nodes: Node[] = await regenerateNodesWithoutFeedback(
      topic,
      id,
      path!,
      children,
      isAdmin
    );
    const edges = getEdgesForNodes(nodes, id);
    const _graph = getLayoutElements(
      [...newGraph.nodes, ...nodes],
      [...newGraph.edges, ...edges],
      "TB"
    );
    setGraph(_graph);
    saveGraph(_graph);
  }

  /*
  This function is called when a node is clicked. It sets the selected node to the clicked node.
  */

  function onNodeClick(node: Node | boolean) {
    if (typeof node === "boolean" || node.id === selectedNode.id) {
      setSelectedNode(temp);
      return;
    }
    setSelectedNode(node);
    setTrayNode(null);
  }
  /*
  This function is called when the options button of a node is clicked. It sets the selected tray node to the clicked node.
  */

  function onHandleClick(node: Node | null) {
    setTrayNode((prev) => {
      if (prev === node) {
        return null;
      }
      return node;
    });
    setSelectedNode(temp);
  }

  /*
  This function adds more theory to the given node by fetching the theory from the API and appending it to the existing theory.
  */
  async function addMoreTheory(node: Node) {
    const topic: string = root!.data.text;
    const oldTheory: string = node.data.theory;
    const path = getPath(graph, root.id, node.id);
    const theory: string = await getTheory(topic, oldTheory, path!);
    node.data.theory += theory;
  }

  /*
  This function generates a unique ID for a graph and adds it to the root node.
  */

  function generateID(): string {
    const id = Date.now().toString();
    setGraphID(id);
    return id;
  }

  /*
  This function stores a graph with the given ID from the local storage.
  */
  function saveGraph(_graph: Graph = graph): string {
    const id = graphID || generateID();
    const graphs: GraphList[] = JSON.parse(
      localStorage.getItem("graphs") || "[]"
    );
    const filteredGraphs = graphs.filter((graph) => graph.id !== id);
    filteredGraphs.push({ id: id, graph: _graph });
    localStorage.setItem("graphs", JSON.stringify(filteredGraphs));
    setIsGenerating(false);
    return id;
  }

  /*
  This function loads a graph with the given ID from the local storage.
  */
  const loadGraph = useCallback(
    (id: string) => {
      const graphs: GraphList[] = JSON.parse(
        localStorage.getItem("graphs") || "[]"
      );
      const graph = graphs.find((graph) => graph.id === id);

      if (!graph) {
        resetGraph();
        return false;
      }

      if (graph && id !== graphID) {
        setGraph(getLayoutElements(graph.graph.nodes, graph.graph.edges, "TB"));
        setGraphID(id);
        return true;
      }
      return false;
    },
    [graphID]
  );

  /*
  This function resets the context
  */

  function resetGraph() {
    setGraph({
      nodes: [],
      edges: [],
    });
    setSelectedNode(temp);
    setTrayNode(null);
    setGraphID("");
  }

  /*
  This function updates the mind map name
  */

  function renameMap(name: string) {
    const newGraph = { ...graph };
    newGraph.nodes[0].data.text = name;
    setGraph(newGraph);
    saveGraph(newGraph);
  }

  /*
  This function updates the user's selected option
  */

  function answerQuestion(option: string, index: number) {
    selectedNode.data.questions[index].selectedOption = option;
    saveGraph(graph);
  }

  /*
  This function asks for a custom question from a node
  */

  async function askQuestion(node: Node, question: string) {
    setIsGenerating(true);
    const response = await askQuestionFromNode(
      node,
      root.data.text,
      question,
      isAdmin
    );
    const custom_questions = node.data.custom_questions || [];
    custom_questions.push({
      question: question,
      answer: response,
    });
    node.data.custom_questions = custom_questions;
    saveGraph(graph);
    setIsGenerating(false);
  }

  async function redoContent(node: Node) {
    setIsGenerating(true);

    const newContent = await regenerateContentWithoutFeedback(
      node,
      root.data.text,
      isAdmin
    );
    node.data.theory = newContent;
    saveGraph(graph);
    setIsGenerating(false);
  }

  return (
    <ReactFlowProvider>
      <MindMapContext.Provider
        value={{
          root,
          graph,
          setGraph,
          generateGraph,
          expandNode,
          regenerateChildren,
          deleteNode,
          onNodeClick,
          selectedNode,
          addMoreTheory,
          trayNode,
          onHandleClick,
          loadGraph,
          graphID,
          answerQuestion,
          isGenerating,
          renameMap,
          askQuestion,
          redoContent,
        }}
      >
        {children}
      </MindMapContext.Provider>
    </ReactFlowProvider>
  );
}

export default MindMapContext;
