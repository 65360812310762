import { useState } from "react";
import { YoutubeResource } from "../../../types/YoutubeResource";
import ResourceCard from "../../ResourceCard/ResourceCard";
import ResourceCardExpanded from "../../ResourceCardExpanded/ResourceCardExpanded";
import "./SidepanelResources.scss";

interface SidepanelResourcesProps {
  videos: YoutubeResource[];
}

export default function SidepanelResources({
  videos,
}: SidepanelResourcesProps) {
  const [selectedResource, setSelectedResource] = useState<number>(-1);

  function handleResourceSelect(index: number) {
    setSelectedResource(index);
  }

  return (
    <div className="side_panel_resources">
      <div className="side_panel_resources__title">Resources</div>
      {selectedResource === -1 ? (
        <div className="side_panel_resources__resources">
          {videos.map((video, index) => {
            return (
              <ResourceCard
                {...video}
                handleResourceSelect={() => {
                  handleResourceSelect(index);
                }}
                key={index}
              />
            );
          })}
        </div>
      ) : (
        <ResourceCardExpanded {...videos[selectedResource]} handleResourceSelect={() => {
          handleResourceSelect(-1);
        }}></ResourceCardExpanded>
      )}
    </div>
  );
}
