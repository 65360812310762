import { useReactFlow } from "reactflow";
import "./ZoomBox.scss";
import { useEffect, useState } from "react";
import useMindMap from "../../hooks/useMindMap";

const duration = 1000;

export default function ZoomBox() {
  const { zoomIn, zoomOut, getZoom } = useReactFlow();

  const {graph} = useMindMap();

  function currentZoom() {
    return (getZoom() * 100).toFixed(0);
  }

  const [zoom, setZoom] = useState(currentZoom());

  useEffect(() => {
    setZoom((getZoom() * 100).toFixed(0));
  }, [graph, getZoom]);

  function handleZoomIn() {
    zoomIn({
      duration,
    });
    setTimeout(() => {
      setZoom(currentZoom());
    }, duration);
  }

  function handleZoomOut() {
    zoomOut({
      duration,
    });
    setTimeout(() => {
      setZoom(currentZoom());
    }, duration);
  }

  return (
    <div className="zoom_box">
      <div className="zoom_box__button" onClick={handleZoomOut}>
        -
      </div>
      <div className="zoom_box__text">{zoom}%</div>
      <div className="zoom_box__button" onClick={handleZoomIn}>
        +
      </div>
    </div>
  );
}
