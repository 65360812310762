import CreateMapCard from "../../components/CreateMapCard/CreateMapCard";
import MapCard, { MapCardProps } from "../../components/MapCard/MapCard";
import "./Home.scss";
import MainSection from "../../components/MainSection/MainSection";
import star from "../../assets/images/star.svg";
import plus from "../../assets/images/plus.svg";
import Navbar from "../../components/Navbar/Navbar";
import { useEffect, useState } from "react";
import { GraphList } from "../../contexts/MindMapContext";
import { formatTime } from "../../scripts/formatTime";

export default function Home() {
  const [mapCards, setMapCards] = useState<MapCardProps[]>([]);
  const [filteredMapCards, setFilteredMapCards] = useState<MapCardProps[]>([]);


  useEffect(() => {
    function onGraphChange() {
      const graphs: GraphList[] = JSON.parse(
        localStorage.getItem("graphs") || "[]"
      );

      const mapCards: MapCardProps[] = graphs.map((ele) => {
        const id = ele.id;
        const time = formatTime(parseInt(id));
        return {
          title: ele.graph.nodes[0].data.text,
          time: time,
          id: id,
        };
      });

      const sortedMapCards = mapCards.sort(
        (a, b) => parseInt(b.id) - parseInt(a.id)
      );
      setMapCards(sortedMapCards);
      setFilteredMapCards(sortedMapCards);
    }

    onGraphChange();

    window.addEventListener("updateGraphs", onGraphChange);

    return () => {
      window.removeEventListener("updateGraphs", onGraphChange);
    };
  }, []);

  function filterMapCards(search: string) {
    const lowerCaseSearch = search.toLowerCase();
    const filtered = mapCards.filter((card) =>
      card.title.toLowerCase().includes(lowerCaseSearch)
    );
    setFilteredMapCards(filtered);
  }

  return (
    <>
      <Navbar onSearch={filterMapCards}></Navbar>
      <div className="home_page">
        <MainSection
          text="Welcome to IWTLNOW"
          title="Your Learning Maps Are Here"
          img1={plus}
          img2={star}
        ></MainSection>
        <div className="home_page__file_list">
          <div className="home_page__file_list__title">Your Learning Maps</div>
          <div className="home_page__file_list__cards">
            <CreateMapCard></CreateMapCard>
            {filteredMapCards.map((ele, idx) => (
              <MapCard {...ele} key={idx}></MapCard>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
