import "./ConfirmationPopup.scss";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import IconComponent from "../IconComponent/IconComponent";

interface ConfirmationPopupProps {
  title: string;
  buttons: React.ReactNode[];
  handleClose: () => void;
  message: JSX.Element
}

export default function ConfirmationPopup({
  buttons,
  handleClose,
  title,
  message
}: ConfirmationPopupProps) {
  return (
    <div className="confirmation_popup">
      <div className="confirmation_popup__popup">
        <div className="confirmation_popup__popup__top">
          <div className="confirmation_popup__popup__top__header">
            <div className="confirmation_popup__popup__top__header__title">
              {title}
            </div>
            <div
              className="confirmation_popup__popup__top__header__close"
              onClick={handleClose}
            >
              <IconComponent Icon={Close}></IconComponent>
            </div>
          </div>
        </div>
        <div className="confirmation_popup__popup__middle">
          {message}
        </div>
        <div className="confirmation_popup__popup__bottom">
          {buttons.map((button, index) => (
            <div key={index}>{button}</div>
          ))}
        </div>
      </div>
    </div>
  );
}
