import { useEffect, useState } from "react";
import "./APIKeyInput.scss";
import ConfirmButton from "../../ConfirmButton/ConfirmButton";
import { verifyAPIKey } from "../../../API_Calls/verifyAPIKey";
import { getErrorToast, getSuccessToast } from "../../../utils/toastUtils";

export default function APIKeyInput() {
  const [apiKey, setApiKey] = useState<string>("");

  function handleApiKeyChange(event: React.ChangeEvent<HTMLInputElement>) {
    setApiKey(event.target.value);
  }

  useEffect(() => {
    const storedApiKey = localStorage.getItem("api_key");
    if (storedApiKey) {
      setApiKey(storedApiKey);
    }
  }, []);

  function handleSave() {
    verifyAPIKey(apiKey).then((result) => {
      if (result) {
        getSuccessToast("Saved");
        localStorage.setItem("api_key", apiKey);
      } else {
        getErrorToast("API key is invalid");
      }
    });
  }

  return (
    <div className="api_key_input">
      <div className="api_key_input__header">
        <div className="api_key_input__header__title">ChatGPT API Key</div>
        <div className="api_key_input__header__help">
          <a
            href="https://community.openai.com/t/how-do-i-get-my-api-key/29343"
            target="_blank"
            rel="noreferrer"
          >
            Learn how
          </a>
        </div>
      </div>
      <div className="api_key_input__input">
        <input
          placeholder="Enter your Chatgpt / OpenAI Api Key"
          onChange={handleApiKeyChange}
          value={apiKey}
          type="password"
        ></input>
      </div>
      <div className="api_key_input__save_button">
        <ConfirmButton onClick={handleSave} text="Authenticate"></ConfirmButton>
      </div>
    </div>
  );
}
