import "./NavRefresher.scss";
import open from "../../../assets/icons/open.svg";
import download from "../../../assets/icons/download.svg";
import NavbarBrand from "../NavbarBrand/NavbarBrand";

export default function NavRefresher() {
  return (
    <div className="nav_refresher">
      <div className="nav_refresher__left">
        <NavbarBrand></NavbarBrand>
      </div>
      <div className="nav_refresher__right">
        <div className="nav_refresher__right__open">
          <img src={open} alt="open"></img>
          Open Map
        </div>
        <div className="nav_refresher__right__download">
          <img src={download} alt="open"></img>
          Download
        </div>
      </div>
    </div>
  );
}
