import OpenAI from "openai";

export async function verifyAPIKey(apiKey: string): Promise<boolean> {
  const configuration = {
    apiKey: apiKey,
    dangerouslyAllowBrowser: true,
  };

  const openai = new OpenAI(configuration);

  try {
    await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      max_tokens: 5,
      messages: [
        {
          role: "user",
          content: "This is a test message.",
        },
      ],
    });
    return true;
  } catch (error) {
    return false;
  }
}
