import { interpolateString } from "../../scripts/interpolateString";
import { loadPrompt } from "../../scripts/loadPrompt";
import {
  custom_question_prompt,
  first_prompt,
  fourth_prompt,
  redo_content_prompt,
  second_prompt,
  third_prompt,
} from "./recursivePrompts";

export function getPromptNode1(topic: string): string {
  return interpolateString(loadPrompt("initialPromptNode", first_prompt), {
    topic,
  });
}

export function getPromptLevel1(topic: string, subtopic: string): string {
  return interpolateString(
    loadPrompt("expansionPromptFirstLevel", second_prompt),
    {
      topic,
      subtopic,
    }
  );
}

export function getPromptLevel2(
  topic: string,
  subtopic: string,
  summary: string
) {
  return interpolateString(
    loadPrompt("expansionPromptNthLevel", third_prompt),
    { topic, subtopic, summary }
  );
}

export function getPromptRegenerateLevelWithoutFeedback(
  nodes: string,
  breadcrumb: string,
  topic: string,
  subtopic: string
) {
  return interpolateString(
    loadPrompt("regenerate_wo_feedback", fourth_prompt),
    {
      nodes,
      breadcrumb,
      topic,
      subtopic,
    }
  );
}
export function getCustomQuestionPrompt(
  topic: string,
  subtopic: string,
  question: string,
  content: string,
  summary: string
) {
  
  return interpolateString(
    loadPrompt("custom_question_prompt", custom_question_prompt),
    {
      topic,
      subtopic,
      question,
      content,
      summary,
    }
  );
}

export function getPromptRegenerateContentWithoutFeedback(
  topic: string,
  subtopic: string,
  content: string,
  summary: string
) {
  return interpolateString(
    loadPrompt("redo_content_prompt", redo_content_prompt),
    {
      topic,
      subtopic,
      content,
      summary,
    }
  );
}
