import Sidepanel from "../../components/Sidepanel/Sidepanel";
import Navbar from "../../components/Navbar/Navbar";
import "./MindmapPage.scss";
import Workspace from "./Workspace/Workspace";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useMindMap from "../../hooks/useMindMap";

export default function MindmapPage() {
  const { mapID } = useParams();
  

  const { loadGraph, selectedNode } = useMindMap();

  const isNodeSelected = selectedNode.id !== "temp-node-1";

  useEffect(() => {
    if (mapID) {
      loadGraph(mapID);
    }
  }, [mapID, loadGraph]);

  return (
    <div className="mindmap_page">
      <div className="mindmap_page__top_color"></div>
      <div className="mindmap_page__left">
        <Navbar></Navbar>
        <div
          className={`mindmap_page__left__workspace ${
            isNodeSelected ? "side-panel-open" : ""
          }`}
        >
          <Workspace></Workspace>
        </div>
      </div>
      <div className="mindmap_page__right">
        <Sidepanel></Sidepanel>
      </div>
      <div className="mindmap_page__bottom_color"></div>
    </div>
  );
}
