import { removeOldResponse } from "../API_Calls/axios/dbHandler";
import { db, ref, onValue } from "./firebase";

export function getFirebaseData(id: string) {
  const promptRef = ref(db, `prompts/${id}`);
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error("No update for 5 minutes"));
    }, 300000);

    const unsubscribe = onValue(promptRef, (snapshot) => {
      if (snapshot.exists()) {
        clearTimeout(timeoutId);

        const data = snapshot.val().data;
        const cost = data.cost;
        const oldCost = JSON.parse(localStorage.getItem("cost") || "{}");

        if (oldCost[cost.model] === undefined) {
          oldCost[cost.model] = 0;
        }

        oldCost[cost.model] += cost.totalCost;
        localStorage.setItem("cost", JSON.stringify(oldCost));
        removeOldResponse().then(() => {
          resolve(data.data);
          unsubscribe();
        });
      }
    });
  });
}
