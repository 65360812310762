import "./PromptSidePanel.scss";
import close from "../../assets/icons/close.svg";
import Input from "../Input/Input";
import { useEffect, useState } from "react";
import { Node } from "reactflow";
import { Prompt } from "../../types/Prompt";
import { getSuccessToast } from "../../utils/toastUtils";

interface PromptSidePanelProps {
  selectedNode: Node | undefined;
  setSelectedNode: (node: Node | undefined) => void;
}

export default function PromptSidePanel({
  selectedNode,
  setSelectedNode,
}: PromptSidePanelProps) {
  const [prompt, setPrompt] = useState<string>("");

  function handleClose() {
    setSelectedNode(undefined);
  }

  function handleSavePrompt() {
    const prompts: Prompt[] = JSON.parse(
      localStorage.getItem("prompts") || "[]"
    )
    const oldPrompt = prompts.find((p: Prompt) => p.id === selectedNode!.data.id)!;
    oldPrompt.text = prompt;
    const newPrompts = prompts.filter((p: Prompt) => p.id !== selectedNode!.data.id);
    newPrompts.push(oldPrompt)
    localStorage.setItem("prompts", JSON.stringify(newPrompts));
    getSuccessToast("Saved!");
  }

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setPrompt(event.target.value);
  }

  useEffect(() => {
    if (selectedNode) setPrompt(selectedNode.data.prompt);
  }, [selectedNode]);

  if (!selectedNode) {
    return <></>;
  }

  return (
    <div className="prompt_side_panel">
      <div className="prompt_side_panel__top">
        <div className="prompt_side_panel__top__title">Manage Prompt</div>
        <div className="prompt_side_panel__top__close" onClick={handleClose}>
          <img src={close} alt="close" />
        </div>
      </div>
      <div className="prompt_side_panel__middle">
        <div className="prompt_side_panel__middle__input">
          <Input onChange={handleChange} placeholder="" value={prompt}></Input>
        </div>
      </div>
      <div className="prompt_side_panel__bottom">
        <button className="prompt_side_panel__bottom__button" onClick={handleSavePrompt}>Save Prompt</button>
      </div>
    </div>
  );
}
