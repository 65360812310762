import "./Input.scss";

interface InputProps {
  placeholder: string;
  value: string;
  isDisabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export default function Input({ placeholder, value, onChange, isDisabled }: InputProps) {
  return (
    <textarea
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="input_component"
      disabled={isDisabled}
    ></textarea>
  );
}
