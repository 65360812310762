import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { YoutubeResource } from "../../types/YoutubeResource";
import IconComponent from "../IconComponent/IconComponent";
import "./ResourceCardExpanded.scss";


interface ResourceCardExpandedProps extends YoutubeResource {
    handleResourceSelect: () => void
}

export default function ResourceCardExpanded({
  description,
  title,
  url,
  handleResourceSelect
}: ResourceCardExpandedProps) {
  const convertToEmbedLink = (url: string): string => {
    const urlParams = new URLSearchParams(new URL(url).search);
    
    const videoId = urlParams.get("v");

    if (!videoId) {
      throw new Error("Invalid YouTube URL");
    }

    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return embedUrl;
  };
  return (
    <div className="resource_card_expanded">
      <div className="resource_card_expanded__header">
        <div className="resource_card_expanded__header__title">Resources</div>
        <div className="resource_card_expanded__header__close" onClick={handleResourceSelect}>
          <IconComponent Icon={Close}></IconComponent>
        </div>
      </div>
      <div className="resource_card_expanded__video">
        <iframe
          src={convertToEmbedLink(url)}
          title={title}
          allowFullScreen
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
          }}
        ></iframe>
      </div>
      <div className="resource_card_expanded__data">
        <div className="resource_card_expanded__data__title">{title}</div>
        <div className="resource_card_expanded__data__description">{description}</div>
      </div>
    </div>
  );
}
