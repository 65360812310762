import ReactFlow, { Background, BackgroundVariant, MiniMap, Node } from "reactflow";
import "./PromptMap.scss";
import { useMemo } from "react";
import PromptNode from "../PromptNode/PromptNode";
import ActionNode from "../ActionNode/ActionNode";
import populatePrompts from "./populatePrompts";

interface PromptMapProps {
  setSelectedNode : (node : Node) => void
}

export default function PromptMap({setSelectedNode} : PromptMapProps) {

  const graph = populatePrompts();

  const nodeTypes = useMemo(
    () => ({ promptNode: PromptNode, actionNode: ActionNode }),
    []
  );

  function onNodeClick(event: React.MouseEvent, node: Node) {
    event.preventDefault();
    if(node.type === "promptNode") {
      setSelectedNode(node);
    }
  }

  return (
    <div className="prompt_map">
      <div className="prompt_map__map">
        <ReactFlow
          nodes={graph.nodes}
          edges={graph.edges}
          onNodesChange={() => {}}
          onEdgesChange={() => {}}
          onNodeClick={onNodeClick}
          nodesDraggable={false}
          nodeTypes={nodeTypes}
          fitView
        >
          <Background
            variant={BackgroundVariant.Dots}
            size={5}
            color="#f0f0f0"
            gap={40}
          />
          <MiniMap />
        </ReactFlow>
      </div>
    </div>
  );
}
