import "./CreateMapCard.scss";
import plus from "../../assets/icons/plus.svg";
import { useNavigate } from "react-router-dom";

export default function CreateMapCard() {

  const navigate = useNavigate();

  function handleOnClick() {
    navigate("/map/new");
  }

  return (
    <div className="create_map_card" onClick={handleOnClick}>
      <div className="create_map_card__image">
        <img src={plus} alt="create"></img>
      </div>
      <div className="create_map_card__content">Create New Map</div>
    </div>
  );
}
