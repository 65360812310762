import logo1 from "../../../assets/logos/logo_desktop_sm.png";
import logo2 from "../../../assets/logos/logo_desktop_lg.png";
import logo3 from "../../../assets/logos/Mobile.png";
import "./NavbarBrand.scss";
import { useNavigate } from "react-router-dom";

interface NavbarBrandProps {
  className?: string;
  isSmall?: boolean;
}

export default function NavbarBrand({ className, isSmall }: NavbarBrandProps) {
  const navigate = useNavigate();

  function handleRedirect() {
    navigate("/home");
  }

  if (isSmall) {
    return (
      <div className={`navbar_brand__small`} onClick={handleRedirect}>
        <img src={logo3} alt="iwtlnow" className="navbar_brand__desktop"></img>
      </div>
    );
  }
  return (
    <div className={`navbar_brand ${className}`} onClick={handleRedirect}>
      {isSmall ? (
        <img src={logo2} alt="iwtlnow" className="navbar_brand__mobile"></img>
      ) : (
        <img src={logo1} alt="iwtlnow" className="navbar_brand__desktop"></img>
      )}
    </div>
  );
}
