import "./OptionTray.scss";
import { ReactComponent as Open } from "../../assets/icons/open.svg";
import { ReactComponent as Refresh } from "../../assets/icons/refresh.svg";
import { ReactComponent as Dustbin } from "../../assets/icons/delete-2.svg";
import useMindMap from "../../hooks/useMindMap";
import { Tooltip } from "react-tooltip";
import IconComponent from "../IconComponent/IconComponent";
import { useState } from "react";
import DeletePopUp from "./PopUps/DeletePopUp/DeletePopUp";
import ExpandPopUp from "./PopUps/ExpandPopUp/ExpandPopUp";

export default function OptionTray() {
  const { trayNode, onNodeClick, onHandleClick, regenerateChildren } =
    useMindMap();

  const [confirmationPopup, setConfirmationPopup] = useState<JSX.Element>(
    <></>
  );

  function handlePopUp(PopUp: () => JSX.Element) {
    setConfirmationPopup(<PopUp></PopUp>);
  }

  function handleOpenNode() {
    const temp = trayNode!;
    onHandleClick(null);
    onNodeClick(temp);
  }
  function handleRedoBranches() {
    regenerateChildren(trayNode!);
  }
  const options = [
    {
      src: Open,
      text: "Open Node",
      tooltip: `Open Contents of ${trayNode?.data?.text}`,
      onClick: handleOpenNode,
    },
    {
      src: Open,
      text: "Expand topic",
      tooltip: `Expand the children of ${trayNode?.data?.text}`,
      onClick: () => handlePopUp(ExpandPopUp),
    },
    {
      src: Refresh,
      text: "Redo Branches",
      tooltip: `Redo Contents for the child
      nodes of ${trayNode?.data?.text}`,
      onClick: handleRedoBranches,
    },
    {
      src: Dustbin,
      text: "Delete Node",
      className: "red",
      tooltip: `Delete ${trayNode?.data?.text} and its children`,
      onClick: () => handlePopUp(DeletePopUp),
    },
  ];

  if (!trayNode) return <></>;
  return (
    <>
      {confirmationPopup}
      <div className="option_tray slide-in">
        {options.map((option, index) => {
          return (
            <div
              className={`option_tray__button ${option.className}`}
              key={index}
              data-tooltip-id={option.text}
              data-tooltip-place="top"
              onClick={option.onClick}
            >
              <div className="option_tray__button__img">
                <IconComponent Icon={option.src} />
              </div>
              <div className="option_tray__button__text">{option.text}</div>
              <Tooltip className="tooltip" id={option.text}>
                {option.tooltip}
              </Tooltip>
            </div>
          );
        })}
      </div>
    </>
  );
}
