import "./Profile.scss";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { useAuth } from "../../hooks/useAuth";
import { signOut } from "firebase/auth";
import { auth } from "../../service/firebase";
import { useEffect } from "react";
import { loadApiUsage } from "../../scripts/loadApiUsage";
import {ReactComponent as ProfileBG} from "../../assets/icons/profile_bg.svg"

export default function Profile() {
  const navigate = useNavigate();
  const { user, role } = useAuth();

  const isApiKey = localStorage.getItem("api_key")?.length ? true : false;

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!user) {
      timeout = setTimeout(() => {
        navigate("/login");
      }, 1000);
    }

    return () => clearTimeout(timeout);
  }, [user, navigate]);

  function handleSignout() {
    signOut(auth);
  }

  const url = user?.photoURL || "";
  const username = user?.displayName;
  const email = user?.email;

  function getPlanFromRole() {
    if (role !== "admin" && isApiKey) {
      return "Free ++";
    }
    if (role === "guest") {
      return "Free (Guest)";
    } else if (role === "user") {
      return "Free";
    } else if (role === "admin") {
      return "Admin";
    } else {
      return "Free";
    }
  }

  const tableContent = [
    { label: "Current Plan", value: getPlanFromRole() },
    ...loadApiUsage(),
  ];

  return (
    <div className="profile">
      <Navbar></Navbar>
      <div className="profile__content">
        <div className="profile__content__header">Profile</div>
        <div className="profile__content__table">
          <div className="profile__content__table__header">General</div>
          <div className="profile__content__table__content">
            <div className="profile__content__table__content__profile_pic">
              <img src={url} alt="profile_pic"></img>
            </div>
            <div className="profile__content__table__content__profile_data">
              <div className="profile__content__table__content__profile_data__name">
                {username}
              </div>
              <div className="profile__content__table__content__profile_data__email">
                {email}
              </div>
            </div>
          </div>
          <div className="profile__content__table__content">
            {tableContent.map((e, index) => {
              return (
                <div
                  className="profile__content__table__content__item"
                  key={index}
                >
                  <div className="profile__content__table__content__item__label">
                    {e.label}
                  </div>
                  <div className="profile__content__table__content__item__value">
                    {e.value}
                  </div>
                </div>
              );
            })}
          </div>
          <ProfileBG className="profile__content__table__bg"></ProfileBG>
        </div>
        <div className="profile__content__logout">
          <button
            className="profile__content__logout__button"
            onClick={handleSignout}
          >
            Sign Out
          </button>
        </div>
      </div>
    </div>
  );
}
