import { useEffect, useState } from "react";
import "./Question.scss";
import Dropdown from "../Dropdown/Dropdown";
import Input from "../Input/Input";
import send from "../../../assets/icons/send.svg";
import edit from "../../../assets/icons/edit.svg";
import useMindMap from "../../../hooks/useMindMap";

interface QuestionProps {
  type: string;
  handleSubmit: (prompt: string) => void;
  isMapGenerated: boolean;
}

export default function Question({
  type,
  handleSubmit,
  isMapGenerated,
}: QuestionProps) {
  const { root } = useMindMap();

  const [question, setQuestion] = useState<string>("");
  const [dropdown, setDropdown] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    if (isMapGenerated) {
      if (type === "preset") {
        // remove the preset prefix
        const prompt = root.data.prompt?.split(" ").slice(3).join(" ") || "";
        setQuestion(prompt);
      } else {
        setQuestion(root.data.prompt);
      }
    }
  }, [root, isMapGenerated, type]);

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setQuestion(e.target.value);
    if (type === "preset") {
      root.data.prompt = `I want to ${dropdown} ${question}`;
    } else {
      root.data.prompt = question;
    }
  }

  function handleDropdownChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setDropdown(e.target.value);
    if (type === "preset") {
      root.data.prompt = `I want to ${dropdown} ${question}`;
    } else {
      root.data.prompt = question;
    }
  }

  function onSubmit() {
    if (type === "preset") {
      handleSubmit(`I want to ${dropdown} ${question}`);
    } else {
      handleSubmit(question);
    }
  }

  function onEdit() {
    setIsEditing((prev) => !prev);
  }

  return (
    <div className="question_box">
      {type === "preset" && (
        <>
          <div className="question_box__text">I want to</div>
          <Dropdown onChange={handleDropdownChange}></Dropdown>
        </>
      )}
      <div className="question_box__container">
        <Input
          onChange={handleInputChange}
          isDisabled={isMapGenerated && !isEditing}
          value={question}
          onEnter={onSubmit}
        ></Input>
      </div>
      <div
        className="question_box__send"
        onClick={isMapGenerated ? onEdit : onSubmit}
      >
        <div className="question_box__send__button">
          <img src={isMapGenerated ? edit : send} alt="submit"></img>
        </div>
      </div>
    </div>
  );
}
