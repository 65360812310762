import useMindMap from "../../../hooks/useMindMap";
import arrow from "../../../assets/images/arrow.svg";
import "./Workspace.scss";
import Mindmap from "../../../components/Mindmap/Mindmap";
import Footer from "../Footer/Footer";

export default function Workspace() {
  const { graph } = useMindMap();

  const showLearn = graph.nodes.length === 0;

  return (
    <div className="workspace">
      <div className="workspace__content">
        {showLearn && (
          <div className="workspace__content__learn">
            <div className="workspace__content__learn__text">
              What Would You Like to Learn Today?
            </div>
            <div className="workspace__content__learn__arrow">
              <img src={arrow} alt="arrow"></img>
            </div>
          </div>
        )}
        <div className="workspace__content__map">
          <Mindmap edges={graph.edges} nodes={graph.nodes}></Mindmap>
        </div>
      </div>
      <div className="workspace__bottom">
        <Footer></Footer>
      </div>
    </div>
  );
}
