import MapCard, { MapCardProps } from "../../components/MapCard/MapCard";
import "./Refresher.scss";
import MainSection from "../../components/MainSection/MainSection";
import star from "../../assets/images/star-2.svg";
import diamond from "../../assets/images/diamond.svg";
import Navbar from "../../components/Navbar/Navbar";
import { useEffect, useState } from "react";
import { GraphList } from "../../contexts/MindMapContext";
import { formatTime } from "../../scripts/formatTime";

export default function Refresher() {
  const [mapCards, setMapCards] = useState<MapCardProps[]>([]);
  const [filteredMapCards, setFilteredMapCards] = useState<MapCardProps[]>([]);
  useEffect(() => {
    function onGraphChange() {
      const graphs: GraphList[] = JSON.parse(
        localStorage.getItem("graphs") || "[]"
      );

      const mapCards: MapCardProps[] = graphs.map((ele) => {
        const id = ele.id;
        const time = formatTime(parseInt(id));
        return {
          title: ele.graph.nodes[0].data.text,
          time: time,
          id: id,
        };
      });

      const sortedMapCards = mapCards.sort(
        (a, b) => parseInt(b.id) - parseInt(a.id)
      );
      setMapCards(sortedMapCards);
      setFilteredMapCards(sortedMapCards);
    }

    onGraphChange();

    window.addEventListener("updateGraphs", onGraphChange);

    return () => {
      window.removeEventListener("updateGraphs", onGraphChange);
    };
  }, []);
  function filterMapCards(search: string) {
    const lowerCaseSearch = search.toLowerCase();
    const filtered = mapCards.filter((card) =>
      card.title.toLowerCase().includes(lowerCaseSearch)
    );
    setFilteredMapCards(filtered);
  }

  return (
    <>
      <Navbar onSearch={filterMapCards}></Navbar>
      <div className="refresher_page">
        <MainSection
          title="Your Map Refreshers Are Here"
          text="Refreshers are a great way to Recap"
          img1={diamond}
          img2={star}
        ></MainSection>
        <div className="refresher_page__file_list">
          <div className="refresher_page__file_list__title">Your Files</div>
          <div className="refresher_page__file_list__cards">
            {filteredMapCards.map((ele, idx) => (
              <MapCard {...ele} key={idx}></MapCard>
            ))}
            {mapCards.length === 0 && (
              <div className="refresher_page__file_list__cards__no_cards">
                Create learning maps to access their Refresher here.
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
