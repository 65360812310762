import {
  custom_question_prompt,
  first_prompt,
  fourth_prompt,
  redo_content_prompt,
  second_prompt,
  third_prompt,
} from "../API_Calls/promptFlows/recursivePrompts";

export function loadDefaultPrompts() {
  const prompts = [
    {
      text: first_prompt,
      type: "initialPromptNode",
      id: "Default prompt initialPromptNode",
    },

    {
      text: second_prompt,
      type: "expansionPromptFirstLevel",
      id: "Default prompt expansionPromptFirstLevel",
    },
    {
      text: third_prompt,
      type: "expansionPromptNthLevel",
      id: "Default prompt expansionPromptNthLevel",
    },
    {
      text: fourth_prompt,
      type: "regenerate_wo_feedback",
      id: "Default prompt regenerate_wo_feedback",
    },
    {
      text: custom_question_prompt,
      type: "custom_question_prompt",
      id: "Default prompt custom_question_prompt",
    },
    {
      text: redo_content_prompt,
      type: "redo_content_prompt",
      id: "Default prompt redo_content_prompt",
    },
  ];

  const localPrompts = localStorage.getItem("prompts");
  const lastVersion = parseInt(localStorage.getItem("lastVersion") || "0");
  if (lastVersion < 4) {
    localStorage.setItem("lastVersion", "4");
    localStorage.removeItem("graphs");
    localStorage.setItem("prompts", JSON.stringify(prompts));
  }
  if (!localPrompts) {
    localStorage.setItem("prompts", JSON.stringify(prompts));
  }

  prompts.forEach((prompt) => {
    const activePrompt = localStorage.getItem(prompt.type);
    if (!activePrompt) {
      localStorage.setItem(prompt.type, prompt.id);
    }
  });
}
