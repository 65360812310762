import { useState } from "react";
import CancelButton from "../../../CancelButton/CancelButton";
import ConfirmButton from "../../../ConfirmButton/ConfirmButton";
import ConfirmationPopup from "../../../ConfirmationPopup/ConfirmationPopup";
import useMindMap from "../../../../hooks/useMindMap";
import Input from "../../../Input/Input";
import "./ExpandPopUp.scss";

export default function ExpandPopUp() {
  const { trayNode, expandNode } = useMindMap();
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const [inputValue, setInputValue] = useState<string>("");
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const generatedTopics = trayNode?.data.generatedTopics || [];
  const topics = (trayNode?.data.topics || []).filter(
    (topic: string) => !generatedTopics.includes(topic)
  );

  const options = [...topics, "Other"];

  function handleClose() {
    setIsClosed(true);
  }

  function handleExpand() {
    expandNode(trayNode!, inputValue);
    handleClose();
  }

  const confirmationPopupProps = {
    title: `Expanding ${trayNode?.data?.text}`,
    buttons: [
      <CancelButton onClick={handleClose} text="Cancel"></CancelButton>,
      <ConfirmButton onClick={handleExpand} text="Confirm"></ConfirmButton>,
    ],
    handleClose: handleClose,
    message: (
      <ExpandNodeMessage
        inputValue={inputValue}
        setInputValue={setInputValue}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        options={options}
      ></ExpandNodeMessage>
    ),
  };

  if (isClosed) {
    return <></>;
  }
  return <ConfirmationPopup {...confirmationPopupProps}></ConfirmationPopup>;
}

interface ExpandNodeMessageProps {
  selectedOption: number;
  setSelectedOption: (value: number) => void;
  inputValue: string;
  setInputValue: (value: string) => void;
  options: string[];
}

function ExpandNodeMessage({
  selectedOption,
  setSelectedOption,
  inputValue,
  setInputValue,
  options,
}: ExpandNodeMessageProps) {
  function handleInput(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setInputValue(e.target.value);
  }

  return (
    <div className="delete_node_message">
      <div className="delete_node_message__text">
        How do you want to expand this node?
      </div>
      <div className="delete_node_message__options">
        {options.map((option, index) => {
          return (
            <div
              className={`delete_node_message__options__option ${
                selectedOption === index ? "selected" : ""
              }`}
              key={index}
              onClick={() => {
                setSelectedOption(index);
              }}
            >
              {option}
            </div>
          );
        })}
        {selectedOption === options.length - 1 && (
          <div className="delete_node_message__options__option replace_input">
            <Input
              onChange={handleInput}
              placeholder="Enter Your Preference here"
              value={inputValue}
            ></Input>
          </div>
        )}
      </div>
    </div>
  );
}
