import { MiniMap } from "reactflow";
import MapInput from "../../../components/MapInput/MapInput";
import ZoomBox from "../../../components/ZoomBox/ZoomBox";
import "./Footer.scss";
import useMindMap from "../../../hooks/useMindMap";
import { useEffect, useRef, useState } from "react";
import OptionTray from "../../../components/OptionTray/OptionTray";
import PopUp from "./PopUp/PopUp";
import Loader from "../../../components/Loader/Loader";
import { useAuth } from "../../../hooks/useAuth";

export default function Footer() {
  const { trayNode, isGenerating } = useMindMap();
  const { role } = useAuth();
  const footerRef = useRef<HTMLDivElement>(null);
  const [popUp, setPopUp] = useState<JSX.Element>(<></>);

  useEffect(() => {
    if (!footerRef.current) {
      return;
    }
    let resizeTimeout: NodeJS.Timeout;
    const handleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        const footer = footerRef.current;
        if (footer) {
          if (footer.offsetWidth < 1023) {
            footer.classList.add("--narrow");
          } else {
            footer.classList.remove("--narrow");
          }
        }
      }, 100);
    };
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(footerRef.current);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (role !== "admin" && !localStorage.getItem("api_key")) {
        setPopUp(
          <PopUp
            onClose={() => {
              setPopUp(<></>);
            }}
          ></PopUp>
        );
      }
    }, 2000);
    return () => clearTimeout(timeout);
  }, [role]);

  function showAuthHelp() {
    setPopUp(
      <PopUp
        onClose={() => {
          setPopUp(<></>);
        }}
      ></PopUp>
    );
  }

  function getMiddleElement() {
    if (isGenerating) {
      return <Loader />;
    } else if (trayNode) {
      return <OptionTray></OptionTray>;
    }
    return <MapInput></MapInput>;
  }

  return (
    <>
      {popUp}
      <div className="mindmap_page__footer" ref={footerRef}>
        <div className="mindmap_page__footer__help" onClick={showAuthHelp}>
          ?
        </div>
        <div className="mindmap_page__footer__left">
          <ZoomBox></ZoomBox>
        </div>
        <div className="mindmap_page__footer__middle">{getMiddleElement()}</div>
        <div className="mindmap_page__footer__right">
          <MiniMap
            pannable
            zoomable
            className="mindmap_page__footer__right__minimap"
          ></MiniMap>
        </div>
      </div>
    </>
  );
}
