import "./NavHome.scss";
import { ReactComponent as Home } from "../../../assets/icons/home.svg";
import { ReactComponent as Maps } from "../../../assets/icons/open-2.svg";
import { ReactComponent as Refresher } from "../../../assets/icons/refresh.svg";
import { useNavigate } from "react-router-dom";
import Settings from "../Settings/Settings";
import NavbarBrand from "../NavbarBrand/NavbarBrand";
import { useEffect, useRef, useState } from "react";
import useMindMap from "../../../hooks/useMindMap";
import Searchbar from "./Searchbar/Searchbar";

interface NavHomeProps {
  onSearch?: (search:string) => void;
}

export default function NavHome({ onSearch }: NavHomeProps) {
  const navigate = useNavigate();
  const path = document.location.pathname;
  const navRef = useRef<HTMLDivElement>(null);
  const [isSmall, setIsSmall] = useState<boolean>(true);
  const [showButtonText, setShowButtonText] = useState<boolean>(false);
  const [isSearchboxOpen, setIsSearchboxOpen] = useState<boolean>(false);
  const { graphID } = useMindMap();

  const buttons = [
    {
      onClick: () => {
        navigate(`/map/${graphID || "new"}`);
      },
      icon: <Home></Home>,
      text: "Home",
      isActive: path.includes("/map/"),
    },
    {
      onClick: () => {
        navigate("/home");
      },
      icon: <Maps></Maps>,
      text: "Learning Maps",
      isActive: path === "/home",
    },
    {
      onClick: () => {
        navigate("/refresher");
      },
      icon: <Refresher></Refresher>,
      text: "Refresher",
      isActive: path === "/refresher",
    },
  ];

  function handleProfile() {
    navigate("/profile");
  }

  function getClassName(): string {
    if (path === "/refresher") {
      return "blue";
    }
    return "";
  }

  function handleButtonText(navBar: HTMLDivElement) {
    if (navBar.offsetWidth < 920) {
      setShowButtonText(false);
    } else {
      setShowButtonText(true);
    }
  }

  useEffect(() => {
    if (!navRef.current) {
      return;
    }

    const handleResize = () => {
      const navBar = navRef.current;
      if (navBar) {
        if (navBar.offsetWidth < 768) {
          setIsSmall(true);
        } else {
          setIsSmall(false);
        }
        handleButtonText(navBar);
      }
    };
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(navRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div className={`nav_home ${getClassName()}`} ref={navRef}>
      <div className="nav_home__left">
        <NavbarBrand isSmall={isSmall}></NavbarBrand>
      </div>
      <div className={`nav_home__middle ${isSearchboxOpen ? "hide" : ""}`}>
        {buttons.map((ele, ind) => {
          return (
            <button
              className={`nav_home__middle__button${
                ele.isActive ? " active" : ""
              }`}
              onClick={ele.onClick}
              key={ind}
            >
              {ele.icon}
              {showButtonText ? ele.text : ""}
            </button>
          );
        })}
      </div>
      <div className={`nav_home__right ${isSearchboxOpen ? "stretch" : ""}`}>
        <Searchbar
          setIsSearchboxOpen={setIsSearchboxOpen}
          isSmall={isSmall}
          onSearch={onSearch!}
        ></Searchbar>
        <Settings></Settings>
        <div className="nav_home__right__profile" onClick={handleProfile}></div>
      </div>
    </div>
  );
}
