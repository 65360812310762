import axios from "axios";
import { auth } from "../../service/firebase";
import { getErrorToast } from "../../utils/toastUtils";

const axiosInstance = axios.create({
  baseURL: "https://iwtlnow-tasks-v6t3zoypjq-el.a.run.app",
  // baseURL: "http://localhost:5000/",
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    getErrorToast("An unexpected error occurred, please try again!");
    return Promise.reject(error);
  }
);

export default axiosInstance;
