import "./APIKeyEstimation.scss";

export default function APIKeyEstimation() {
  return (
    <div className="api_key_estimation">
      <div className="api_key_estimation__header">Estimated Cost Breakdown</div>
      <table className="api_key_estimation__table">
        <tbody>
          <tr>
            <td>Cost Per Mindmap</td>
            <td>$TBE</td>
          </tr>
          <tr>
            <td>Overall Cost*</td>
            <td>$TBE</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
