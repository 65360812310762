import { useLocation } from "react-router-dom";
import NavHome from "./NavHome/NavHome";
import NavMap from "./NavMap/NavMap";
import NavRefresher from "./NavRefresher/NavRefresher";

interface NavbarProps {
  onSearch?: (search:string) => void;
}

export default function Navbar({ onSearch }: NavbarProps) {
  const location = useLocation();
  const path = location.pathname;

  if (path.startsWith("/map/")) {
    return <NavMap />;
  }
  if (path.startsWith("/refresher/")) {
    return <NavRefresher />;
  }

  return <NavHome onSearch={onSearch} />;
}
