import IconComponent from "../../IconComponent/IconComponent";
import { ReactComponent as Premium } from "../../../assets/icons/premium.svg";
import "./PremiumCTA.scss";

export default function PremiumCTA() {
  function onClick() {
  }

  return (
    <div className="api_key_popup_premium_cta">
      <div className="api_key_popup_premium_cta__header">
        <div className="api_key_popup_premium_cta__header__line">
          <span>or</span>
        </div>
      </div>
      <div className="api_key_popup_premium_cta__body">
        <div className="api_key_popup_premium_cta__body__subtitle">
          Save the trouble and let us handle things for you
        </div>
        <div
          className="api_key_popup_premium_cta__body__button"
          onClick={onClick}
        >
          <div className="api_key_popup_premium_cta__body__button__icon">
            <IconComponent Icon={Premium}></IconComponent>
          </div>
          <div className="api_key_popup_premium_cta__body__button__text">
            Upgrade to Premium
          </div>
        </div>
      </div>
    </div>
  );
}
