import { useCallback, useMemo } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Edge,
  Node,
  OnEdgesChange,
  OnNodesChange,
  applyEdgeChanges,
  applyNodeChanges,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import CustomNode from "../CustomNode/CustomNode";
import useMindMap from "../../hooks/useMindMap";

interface MindmapProps {
  nodes: Node[];
  edges: Edge[];
}

export default function Mindmap({ nodes, edges }: MindmapProps) {
  const { setGraph, onNodeClick } = useMindMap();
  const { fitView } = useReactFlow();
  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      window.requestAnimationFrame(() => {
        fitView({ duration: 1000 });
      });
      setGraph((nds) => {
        return {
          edges: [...nds.edges],
          nodes: applyNodeChanges(changes, nds.nodes),
        };
      });
    },
    [setGraph, fitView]
  );

  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) =>
      setGraph((nds) => {
        return {
          nodes: [...nds.nodes],
          edges: applyEdgeChanges(changes, nds.edges),
        };
      }),
    [setGraph]
  );

  const onClick = async (event: React.MouseEvent, node: Node) => {
    event.preventDefault();
    onNodeClick(node);
  };

  const nodeTypes = useMemo(() => ({ customNode: CustomNode }), []);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onNodeClick={onClick}
      nodeTypes={nodeTypes}
      fitView
      nodesDraggable={false}
    >
      <Background
        variant={BackgroundVariant.Dots}
        size={5}
        color="#f0f0f0"
        gap={40}
      ></Background>
    </ReactFlow>
  );
}
