import "./ConfirmButton.scss";
import { ReactComponent as Tick } from "../../assets/icons/tick.svg";
import IconComponent from "../IconComponent/IconComponent";

interface ConfirmButtonProps {
  onClick: () => void;
  text: string;
}

export default function ConfirmButton({ onClick, text }: ConfirmButtonProps) {
  return (
    <div className="confirm_button" onClick={onClick}>
      <div className="confirm_button__icon">
        <IconComponent Icon={Tick}></IconComponent>
      </div>
      <div className="confirm_button__text">{text}</div>
    </div>
  );
}
