import { handleMultiplePrompt } from "../axios/mindmap";
import getJson from "../getJson";

export async function handleAPI(prompt: string, isAdmin: boolean) {
  return isAdmin
    ? await handleMultiplePrompt([prompt])
    : [await getJson(prompt)];
}

export async function handleApiWithChildren(prompt: string, isAdmin: boolean) {
  return isAdmin
    ? (((await handleMultiplePrompt([prompt])) as any)[0].children as any[])
    : (((await getJson(prompt)) as any).children as any[]);
}
