import { Node } from "reactflow";
import { Graph } from "../contexts/MindMapContext";

export function handleDownload(graph: Graph, root : Node): void {
  const element = document.createElement("a");
  const file = new Blob([JSON.stringify(graph)], { type: "text/plain" });
  element.href = URL.createObjectURL(file);
  element.download = `${root.data.text}.json`;
  document.body.appendChild(element);
  element.click();
}
