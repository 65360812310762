import { edges, nodes } from "./prompts";
import { Graph } from "../../contexts/MindMapContext";
import { getLayoutElements } from "../../mapUtils/rearrangeLayout";
import { Prompt } from "../../types/Prompt";

export default function populatePrompts(): Graph {
  const populatedNodes = nodes;
  const prompts : Prompt[] = JSON.parse(localStorage.getItem("prompts") || "{}")

  for(const node of populatedNodes) {
    const id = node.data.id;
    if(!id) {
        continue;
    }    
    (node.data as any).prompt = prompts.find((ele) => ele.id === id)!.text;
  }

  return getLayoutElements(populatedNodes, edges, "LR");
}
