import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue, off } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyASkFX397WaSfyYvfm4XpHG5HfEvdBT6wo",
  authDomain: "comini-iwtlnow.firebaseapp.com",
  projectId: "comini-iwtlnow",
  databaseURL:
    "https://comini-iwtlnow-default-rtdb.asia-southeast1.firebasedatabase.app/",
  storageBucket: "comini-iwtlnow.appspot.com",
  messagingSenderId: "774401788588",
  appId: "1:774401788588:web:7e10ce3602747fdce32d09",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);
export { auth, db, ref, onValue, off };
