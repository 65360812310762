import "./SidepanelTheory.scss";

interface SidepanelMiddleProps {
  theory: string;
  questions?: [
    {
      question: string;
      answer: string;
    }
  ];
}

export default function SidepanelTheory({
  theory,
  questions,
}: SidepanelMiddleProps) {
  return (
    <>
      <div
        className="side_panel_theory"
        dangerouslySetInnerHTML={{ __html: theory }}
      ></div>
      <div className="side_panel_question">
        <div className="side_panel_question__title">Your Questions</div>
        {questions ? (
          <div className="side_panel_question__list">
            {questions.map((question, idx) => {
              return (
                <div className="side_panel_question__list__item" key={idx}>
                  <div className="side_panel_question__list__item__question">
                    {idx + 1}{". " + question.question}
                  </div>
                  <div className="side_panel_question__list__item__answer" dangerouslySetInnerHTML={{ __html: question.answer }}>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            Questions you have regarding the contents in this node can be
            clarified using the custom question button. And the history would be
            displayed here.
          </div>
        )}
      </div>
    </>
  );
}
