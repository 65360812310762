import { signOut } from "firebase/auth";
import axiosInstance from "./axiosInstance";
import { auth } from "../../service/firebase";
import { getFirebaseData } from "../../service/firebaseUtils";
import { removeOldResponse } from "./dbHandler";
import { getErrorToast } from "../../utils/toastUtils";

export async function handlePrompt(prompt: string) {
  try {
    await removeOldResponse();
    const res = await axiosInstance.post("/task/prompt", {
      prompt,
    });
    const id = res.data.id;
    return await getFirebaseData(id);
  } catch (err) {
    await signOut(auth);
    getErrorToast("An unexpected error occurred, please try again!");
    console.log(err);
  }
}

export async function handleMultiplePrompt(prompt: string[]) {
  try {
    await removeOldResponse();
    const res = await axiosInstance.post("/task/prompts", { prompt });
    const id = res.data.id;
    return await getFirebaseData(id);
  } catch (err) {
    await signOut(auth);
    getErrorToast("An unexpected error occurred, please try again!");
    console.error(err);
  }
}
