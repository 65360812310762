interface Prompt {
  id: string;
  text: string;
  type: string;
}

export function loadPrompt(type: string, defaultPrompt: string): string {
  let promptId: string | null = localStorage.getItem(type);
  let prompts: Prompt[] = JSON.parse(localStorage.getItem("prompts") || "[]");
  if (promptId === null) {
    promptId = `Default prompt ${type}`;
    localStorage.setItem(
      "prompts",
      JSON.stringify([...prompts, { id: promptId, text: defaultPrompt, type }])
    );
    localStorage.setItem(type, promptId);
  }

  let prompt: string | undefined = prompts.find(
    (prompt) => prompt.id === promptId
  )?.text;

  if (!prompt) {
    prompt = defaultPrompt;
    localStorage.setItem(type, promptId);
    localStorage.setItem(
      "prompts",
      JSON.stringify([...prompts, { id: promptId, text: defaultPrompt, type }])
    );
  }
  return prompt;
}
