import { Node } from "reactflow";

interface NodeData {
  text: string;
  theory: string;
  questions: { question: string; options: string[]; correctOption: string }[];
}

export function createNode(mergedData: NodeData): Node {
  return {
    id: "node-1",
    type: "customNode",
    position: { x: 0, y: 0 },
    data: {
      ...mergedData,
      handleIds: ["node-1u", "node-1d"],
    },
  };
}

export function createNextNode(
  child: NodeData,
  id: string,
  index: number
): Node {
  return {
    id: `${id}.${index + 1}`,
    type: "customNode",
    position: { x: 150 * (index + 1), y: 0 },
    data: {
      ...child,
      handleIds: [`${id}.${index + 1}u`, `${id}.${index + 1}d`],
    },
  };
}
