import { useEffect, useState } from "react";
import "./SidepanelEvaluation.scss";
import useMindMap from "../../../hooks/useMindMap";

interface Question {
  question: string;
  correctOption: string;
  options: string[];
  selectedOption?: string;
}

export default function SidepanelEvaluation() {
  const { selectedNode, answerQuestion } = useMindMap();
  const questions: Question[] = selectedNode.data.questions;

  const [selectedOptions, setSelectedOptions] = useState<number[]>([]);
  const [status, setStatus] = useState<number[]>([]);

  const isSubmitted = status.filter((e) => e !== -1).length > 0;

  const answeredCount = selectedOptions.filter((e) => e !== -1).length;

  function handleOptionsSelect(question_index: number, option_index: number) {
    if (isSubmitted) {
      return;
    }
    setSelectedOptions((prev) => {
      const arr = [...prev];
      arr[question_index] = option_index;
      return arr;
    });
  }

  function handleSubmit() {
    if (answeredCount !== selectedOptions.length) {
      alert("Answer all the questions");
      return;
    } else if (isSubmitted) {
      return;
    }
    const _status: number[] = [];
    selectedOptions.forEach((option, question) => {
      answerQuestion(questions[question].options[option], question);
      _status.push(
        questions[question].correctOption ===
          questions[question].options[option]
          ? 1
          : 0
      );
    });
    setStatus(_status);
  }

  function getOptionClass(question_index: number, option_index: number) {
    if (selectedOptions[question_index] !== option_index) {
      return "";
    }
    if (status[question_index] === -1) {
      return selectedOptions[question_index] === option_index ? "selected" : "";
    }
    return status[question_index] === 1 ? "correct" : "wrong";
  }

  useEffect(() => {
    if (questions.some((q) => q.selectedOption !== undefined)) {
      setSelectedOptions(
        questions.map((q) =>
          q.selectedOption ? q.options.indexOf(q.selectedOption) : -1
        )
      );
      setStatus(
        questions.map((q) =>
          q.selectedOption ? (q.correctOption === q.selectedOption ? 1 : 0) : -1
        )
      );
    } else {
      setSelectedOptions(questions.map(() => -1));
      setStatus(questions.map(() => -1));
    }
  }, [questions, selectedNode]);

  return (
    <div className="side_panel_evaluation">
      <div className="side_panel_evaluation__title">Evaluation</div>
      <div className="side_panel_evaluation__question_box">
        {questions.map((question, q_index) => {
          return (
            <div
              className={`side_panel_evaluation__question_box__problem`}
              key={q_index}
            >
              <div className="side_panel_evaluation__question_box__problem__question">
                {`${q_index + 1}. ${question.question}`}
              </div>
              <div className="side_panel_evaluation__question_box__problem__options">
                {question.options.map((option, o_index) => {
                  return (
                    <div
                      className={`side_panel_evaluation__question_box__problem__options__option ${getOptionClass(
                        q_index,
                        o_index
                      )}`}
                      key={o_index}
                      onClick={() => {
                        handleOptionsSelect(q_index, o_index);
                      }}
                    >
                      <div className="side_panel_evaluation__question_box__problem__options__option__left">
                        <div className="side_panel_evaluation__question_box__problem__options__option__left__circle"></div>
                      </div>
                      <div className="side_panel_evaluation__question_box__problem__options__option__right">
                        {option}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className="side_panel_evaluation__question_box__problem__submit">
          <div className="side_panel_evaluation__question_box__problem__submit__status">
            Answered: {answeredCount} <p>•</p>
            Unanswered: {selectedOptions.length - answeredCount}
          </div>
          <div className="side_panel_evaluation__question_box__problem__submit__button">
            Press Submit for results
            <button onClick={handleSubmit} disabled={isSubmitted}>Submit Assessment</button>
          </div>
        </div>
      </div>
    </div>
  );
}
