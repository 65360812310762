import { YoutubeResource } from "../../types/YoutubeResource";
import "./ResourceCard.scss";

interface ResourceCardProps extends YoutubeResource {
  handleResourceSelect: () => void;
}

export default function ResourceCard({
  description,
  thumbnail,
  title,
  handleResourceSelect,
}: ResourceCardProps) {
  const masked_title = title.length > 30 ? title.slice(0, 30) + "..." : title;
  const masked_description =
    description.length > 20
      ? description.slice(0, 20) + "..."
      : description || "No description";
  return (
    <div className="resource_card" onClick={handleResourceSelect}>
      <div className="resource_card__thumbnail">
        <img src={thumbnail} alt={title} />
      </div>
      <div className="resource_card__title">{masked_title}</div>
      <div className="resource_card__description">{masked_description}</div>
    </div>
  );
}
