import { KeyboardEvent } from "react";
import "./Input.scss";

interface InputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled: boolean;
  value: string;
  onEnter?: () => void
}

export default function Input({ onChange, isDisabled, value, onEnter}: InputProps) {

  function handleKeyPress(e : KeyboardEvent<HTMLInputElement>) {
    if(e.key === "Enter") {
      onEnter && onEnter();
    }   
  }

  return (
    <input
      className="map_input_text"
      type="text"
      placeholder="Type your question here"
      onChange={onChange}
      disabled={isDisabled}
      value={value}
      onKeyDown={handleKeyPress}
    ></input>
  );
}
