import { Route, Routes, useNavigate } from "react-router-dom";
import { loadDefaultPrompts } from "./scripts/loadDefaultPrompt";
import Home from "./pages/Home/Home";
import "./App.scss";
import Refresher from "./pages/Refresher/Refresher";
import RefresherMap from "./pages/Refresher/RefresherMap/RefresherMap";
import MindmapPage from "./pages/MindmapPage/MindmapPage";
import { MindMapContextProvider } from "./contexts/MindMapContext";
import { CookiesProvider } from "react-cookie";
import { AuthContextProvider } from "./contexts/AuthContext";
import Profile from "./pages/Profile/Profile";
import Login from "./pages/Login/Login";
import Prompt from "./pages/Prompt/Prompt";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";

function RedirectToMindMapPage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/map/new");
  }, [navigate]);
  return <></>;
}

export default function App() {
  loadDefaultPrompts();
  return (
    <CookiesProvider>
      <AuthContextProvider>
        <MindMapContextProvider>
          <ToastContainer limit={1} />
          <Routes>
            <Route
              path="/"
              element={<RedirectToMindMapPage></RedirectToMindMapPage>}
            ></Route>
            <Route path="/home" element={<Home></Home>}></Route>
            <Route path="/profile" element={<Profile></Profile>}></Route>
            <Route path="/login" element={<Login></Login>}></Route>
            <Route path="/refresher" element={<Refresher></Refresher>}></Route>
            <Route path="/prompts" element={<Prompt></Prompt>}></Route>
            <Route
              path="/refresher/:id"
              element={<RefresherMap></RefresherMap>}
            ></Route>
            <Route
              path="/map/:mapID"
              element={<MindmapPage></MindmapPage>}
            ></Route>
          </Routes>
        </MindMapContextProvider>
      </AuthContextProvider>
    </CookiesProvider>
  );
}
