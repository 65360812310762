import "./Loader.scss"

export default function Loader() {
    return <div className="loader">
        <div className="loader__left">
        <div className="loader__left__box">
            Tailoring the best results for you
            </div>
        </div>
        <div className="loader__right">
            <div className="loader__right__spinner"></div>
        </div>
    </div>
};
