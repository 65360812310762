interface UsageData {
  [key: string]: number;
}

export function loadApiUsage() {
  const data: UsageData = JSON.parse(localStorage.getItem("cost") || "{}");
  return Object.entries(data).map(([key, value]) => {
    return {
      label: `Usage (${key.substring(0, 5)})`,
      value: `${value.toFixed(2)}$`,
    };
  });
}
