import { useState } from "react";
import CancelButton from "../../../CancelButton/CancelButton";
import ConfirmButton from "../../../ConfirmButton/ConfirmButton";
import ConfirmationPopup from "../../../ConfirmationPopup/ConfirmationPopup";
import useMindMap from "../../../../hooks/useMindMap";
import Input from "../../../Input/Input";
import "./DeletePopUp.scss";

export default function DeletePopUp() {
  const { trayNode, onHandleClick, deleteNode } = useMindMap();
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const [inputValue, setInputValue] = useState<string>("");
  const [isClosed, setIsClosed] = useState<boolean>(false);

  function handleClose() {
    setIsClosed(true);
  }

  function handleDelete() {
    const temp = trayNode!;
    onHandleClick(null);
    deleteNode(temp);
  }

  function handleReplace() {
  }

  const confirmOptions = [handleDelete, handleReplace];

  const confirmationPopupProps = {
    title: `Deleting ${trayNode?.data?.text}`,
    buttons: [
      <CancelButton onClick={handleClose} text="Cancel"></CancelButton>,
      <ConfirmButton
        onClick={confirmOptions[selectedOption]}
        text="Confirm"
      ></ConfirmButton>,
    ],
    handleClose: handleClose,
    message: (
      <DeleteNodeMessage
        inputValue={inputValue}
        setInputValue={setInputValue}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      ></DeleteNodeMessage>
    ),
  };

  if (isClosed) {
    return <></>;
  }
  return <ConfirmationPopup {...confirmationPopupProps}></ConfirmationPopup>;
}

interface DeleteNodeMessageProps {
  selectedOption: number;
  setSelectedOption: (value: number) => void;
  inputValue: string;
  setInputValue: (value: string) => void;
}

function DeleteNodeMessage({
  selectedOption,
  setSelectedOption,
  inputValue,
  setInputValue,
}: DeleteNodeMessageProps) {
  function handleInput(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setInputValue(e.target.value);
  }

  const options = ["Delete Node", "Replace Node"];

  return (
    <div className="delete_node_message">
      <div className="delete_node_message__text">
        Are you sure, you want to delete this node? You can replace it with
        tailored contents instead.
      </div>
      <div className="delete_node_message__options">
        {options.map((option, index) => {
          return (
            <div
              className={`delete_node_message__options__option ${
                selectedOption === index ? "selected" : ""
              }`}
              key={index}
              onClick={() => {
                setSelectedOption(index);
              }}
            >
              {option}
            </div>
          );
        })}
        {selectedOption === 1 && (
          <div className="delete_node_message__options__option replace_input">
            <Input
              onChange={handleInput}
              placeholder="Enter Your Preference here"
              value={inputValue}
            ></Input>
          </div>
        )}
      </div>
    </div>
  );
}
