import "./APIKeyPopUp.scss";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import IconComponent from "../IconComponent/IconComponent";
import { useState } from "react";
import APIKeyInput from "./APIKeyInput/APIKeyInput";
import APIKeyEstimation from "./APIKeyEstimation/APIKeyEstimation";
import PremiumCTA from "./PremiumCTA/PremiumCTA";

interface APIKeyPopUpProps {
  closeDropdown: () => void;
}

export default function APIKeyPopUp({ closeDropdown }: APIKeyPopUpProps) {
  const [showPopup, setShowPopup] = useState<boolean>(true);

  function handleClose() {
    setShowPopup(false);
    closeDropdown();
  }

  if (!showPopup) {
    return <></>;
  }

  return (
    <div className="api_key_popup">
      <div className="api_key_popup__popup">
        <div className="api_key_popup__popup__top">
          <div className="api_key_popup__popup__top__header">
            <div className="api_key_popup__popup__top__header__title">
              API Key
            </div>
            <div
              className="api_key_popup__popup__top__header__close"
              onClick={handleClose}
            >
              <IconComponent Icon={Close}></IconComponent>
            </div>
          </div>
        </div>
        <div className="api_key_popup__popup__middle">
          <APIKeyInput></APIKeyInput>
          <APIKeyEstimation></APIKeyEstimation>
          <PremiumCTA></PremiumCTA>
        </div>
      </div>
    </div>
  );
}
