import { MarkerType } from "reactflow";

const markerEnd = {
  type: MarkerType.ArrowClosed,
  width: 40,
  height: 40,
  color: "#000",
};

export const nodes = [
  {
    id: "1",
    data: {
      text: "User enters initial prompt",
      handleIds: ["node-1u", "node-1d"],
    },
    position: { x: 250, y: 0 },
    type: "actionNode",
  },
  {
    id: "2",
    data: {
      text: "Prompt for first node",
      handleIds: ["node-2u", "node-2d"],
      id: "Default prompt initialPromptNode",
    },
    position: { x: 250, y: 100 },
    type: "promptNode",
  },
  {
    id: "3",
    data: {
      text: "Prompt for first level",
      handleIds: ["node-3u", "node-3d"],
      id: "Default prompt expansionPromptFirstLevel",
    },
    position: { x: 250, y: 200 },
    type: "promptNode",
  },
  {
    id: "4",
    data: {
      text: "Prompt for node expansion",
      handleIds: ["node-4u", "node-4d"],
      id: "Default prompt expansionPromptNthLevel",
    },
    position: { x: 500, y: 200 },
    type: "promptNode",
  },
  {
    id: "5",
    data: {
      text: "Prompt for regeneration",
      handleIds: ["node-5u", "node-5d"],
      id: "Default prompt regenerate_wo_feedback",
    },
    position: { x: 250, y: 300 },
    type: "promptNode",
  },
  {
    id: "6",
    data: {
      text: "Mindmap gets updated",
      handleIds: ["node-6u", "node-6d"],
    },
    position: { x: 250, y: 400 },
    type: "actionNode",
  },
  {
    id: "8",
    data: {
      text: "User wants to expand the current node",
      handleIds: ["node-8u", "node-8d"],
    },
    position: { x: 500, y: 100 },
    type: "actionNode",
  },
  {
    id: "9",
    data: {
      text: "User wants to regenerate children",
      handleIds: ["node-9u", "node-9d"],
    },
    position: { x: 0, y: 400 },
    type: "actionNode",
  },
  {
    id: "10",
    data: {
      text: "User wants to ask a question at a node",
      handleIds: ["node-10u", "node-10d"],
    },
    position: { x: 0, y: 400 },
    type: "actionNode",
  },
  {
    id: "11",
    data: {
      text: "Prompt for asking a question",
      handleIds: ["node-11u", "node-11d"],
      id: "Default prompt custom_question_prompt",
    },
    position: { x: 250, y: 300 },
    type: "promptNode",
  },
  {
    id: "12",
    data: {
      text: "User wants to regenerate content of a node",
      handleIds: ["node-10u", "node-10d"],
    },
    position: { x: 0, y: 400 },
    type: "actionNode",
  },
  {
    id: "13",
    data: {
      text: "Prompt for regenerating content",
      handleIds: ["node-11u", "node-11d"],
      id: "Default prompt redo_content_prompt",
    },
    position: { x: 250, y: 300 },
    type: "promptNode",
  },
];

export const edges = [
  {
    id: "e1-2",
    source: "1",
    target: "2",
    type: "smoothstep",
    style: {
      stroke: "#000",
    },
    markerEnd,
  },
  {
    id: "e2-3",
    source: "2",
    target: "3",
    type: "smoothstep",
    style: {
      stroke: "#000",
    },
    markerEnd,
  },
  {
    id: "e3-6",
    source: "3",
    target: "6",
    type: "smoothstep",
    style: {
      stroke: "#000",
    },
    markerEnd,
  },
  {
    id: "e4-6",
    source: "4",
    target: "6",
    type: "smoothstep",
    style: {
      stroke: "#000",
    },
    markerEnd,
  },
  {
    id: "e9-5",
    source: "9",
    target: "5",
    type: "smoothstep",
    style: {
      stroke: "#000",
    },
    markerEnd,
  },
  {
    id: "e8-4",
    source: "8",
    target: "4",
    type: "smoothstep",
    style: {
      stroke: "#000",
    },
    markerEnd,
  },
  {
    id: "e5-6",
    source: "5",
    target: "6",
    type: "smoothstep",
    style: {
      stroke: "#000",
    },
    markerEnd,
  },
  {
    id: "e10-11",
    source: "10",
    target: "11",
    type: "smoothstep",
    style: {
      stroke: "#000",
    },
    markerEnd,
  },
  {
    id: "e11-6",
    source: "11",
    target: "6",
    type: "smoothstep",
    style: {
      stroke: "#000",
    },
    markerEnd,
  },
  {
    id: "e12-13",
    source: "12",
    target: "13",
    type: "smoothstep",
    style: {
      stroke: "#000",
    },
    markerEnd,
  },
  {
    id: "e13-6",
    source: "13",
    target: "6",
    type: "smoothstep",
    style: {
      stroke: "#000",
    },
    markerEnd,
  },
];
