import IconComponent from "../IconComponent/IconComponent";
import "./Button.scss";

interface ButtonProps {
  src: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  onClick: (event?: any) => void;
  children?: React.ReactNode;
  className?: string;
  tooltipProps?: any;
}

export default function Button({
  src,
  onClick,
  children,
  className,
  tooltipProps,
}: ButtonProps) {
  return (
    <button className={`button ${className}`} onClick={onClick} {...tooltipProps}>
      <IconComponent Icon={src}></IconComponent>
      {children}
    </button>
  );
}
