import Navbar from "../../../components/Navbar/Navbar";
import "./RefresherMap.scss";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useMindMap from "../../../hooks/useMindMap";
import { formatTime } from "../../../scripts/formatTime";

export default function RefresherMap() {
  const { loadGraph, root } = useMindMap();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      loadGraph(id);
    }
  }, [id, loadGraph]);

  const title = root.data.text;
  const score = "70%";
  const lastOpened = formatTime(parseInt(id || "0"));

  return (
    <>
      <Navbar></Navbar>
      <div className="refresher_map_page">
        <div className="refresher_map_page__header">
          <div className="refresher_map_page__header__title">
            {title} - Refresher
          </div>
          <div className="refresher_map_page__header__subtitle">
            {score} Overall Score <p>•</p>
            Created on {lastOpened}
          </div>
        </div>
        <div className="refresher_map_page__content">
          <div className="refresher_map_page__content__table">
            <div className="refresher_map_page__content__table__column">
              <div className="refresher_map_page__content__table__column__header">
                Overview
              </div>
              <div className="refresher_map_page__content__table__column__content">
                <div
                  className="refresher_map_page__content__table__column__content__theory"
                  dangerouslySetInnerHTML={{ __html: root.data.theory }}
                ></div>
              </div>
            </div>
            <div className="refresher_map_page__content__table__column">
              <div className="refresher_map_page__content__table__column__header">
                {root.data.text}
              </div>
              <div className="refresher_map_page__content__table__column__content"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
