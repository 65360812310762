import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import "./Login.scss";
import { useNavigate } from "react-router-dom";
import { auth } from "../../service/firebase";
import Navbar from "../../components/Navbar/Navbar";
import { ReactComponent as Google } from "../../assets/icons/google.svg";
import { ReactComponent as LoginBG } from "../../assets/icons/login_bg.svg";
import { getErrorToast } from "../../utils/toastUtils";

const Login = () => {
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate("/profile");
    } catch (error) {
      getErrorToast("An unexpected error occurred, please try again!");
      console.error("Error: ", error);
    }
  };

  return (
    <div className="login_container">
      <Navbar></Navbar>
      <div className="login_container__content">
        <div className="login_container__content__header">
          <div className="login_container__content__header__title">
            Welcome to IWTLNOW
          </div>
          <div className="login_container__content__header__text">
            Let's Get You An Account First
          </div>
        </div>
        <div className="login_container__content__login">
          <button
            className="login_container__content__login__button"
            onClick={handleGoogleSignIn}
          >
            <Google></Google> Continue with Google
          </button>
          <LoginBG className="login_container__content__login__bg"></LoginBG>
        </div>
      </div>
    </div>
  );
};

export default Login;
