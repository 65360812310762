import { Handle, Position, useReactFlow } from "reactflow";
import "./CustomNode.scss";
import useMindMap from "../../hooks/useMindMap";

interface Data {
  text: string;
  handleIds: string[];
}

interface CustomNodeProps {
  data: Data;
}

export default function CustomNode({ data }: CustomNodeProps) {
  const { selectedNode, trayNode, onHandleClick, isGenerating } = useMindMap();
  const { getNode } = useReactFlow();
  let selected = false;
  if (selectedNode.data?.handleIds?.[0] === data.handleIds[0]) {
    selected = true;
  }

  let traySelected = false;

  if (trayNode?.data?.handleIds?.[0] === data.handleIds[0]) {
    traySelected = true;
  }

  const handleTray = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if(isGenerating) {
      return;
    }
    const node = getNode(data.handleIds[0].slice(0, -1));
    if (trayNode?.id === node?.id) {
      onHandleClick(null);
      return;
    }
    onHandleClick(getNode(data.handleIds[0].slice(0, -1)) || null);
  };

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        id={data.handleIds[0]}
        isConnectable={false}
        className="handle_top"
      ></Handle>
      <Handle
        type="source"
        position={Position.Bottom}
        id={data.handleIds[1]}
        isConnectable={false}
        className={`handle_bottom ${traySelected ? "selected" : ""}`}
        onClick={handleTray}
      ></Handle>
      <div className="custom_node" id={selected ? "selected" : ""}>
        {data.text}
      </div>
    </>
  );
}
