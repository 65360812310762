import "./CancelButton.scss";
import { ReactComponent as Cross } from "../../assets/icons/close.svg";
import IconComponent from "../IconComponent/IconComponent";

interface ConfirmButtonProps {
  onClick: () => void;
  text: string;
}

export default function CancelButton({ onClick, text }: ConfirmButtonProps) {
  return (
    <div className="cancel_button" onClick={onClick}>
      <div className="cancel_button__icon">
        <IconComponent Icon={Cross}></IconComponent>
      </div>
      <div className="cancel_button__text">{text}</div>
    </div>
  );
}
