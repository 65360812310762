import React, { createContext, useState, useEffect } from "react";
import { User } from "firebase/auth";
import { auth } from "../service/firebase";
import axiosInstance from "../API_Calls/axios/axiosInstance";

interface AuthContextType {
  user: User | null;
  role: string;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

export function AuthContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, setUser] = useState<User | null>(null);
  const [role, setRole] = useState<string>("guest");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      setUser(currentUser);

      if (currentUser) {
        try {
          await axiosInstance.get("/admin/is-admin/");
          setRole("admin");
        } catch (error) {
          console.error("Error checking admin status:", error);
          setRole("user");
        }
      } else {
        setRole("guest");
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, role }}>
      {children}
    </AuthContext.Provider>
  );
}
